import { useState, useEffect, Fragment, useContext } from "react";
import Upgrade from "./Upgrade";
import { useNavigate, useLocation } from 'react-router-dom';
import SkelUpgradeCard from "./SkelUpgradeCard";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { compareDates, currencyFormat, isBadAmount, extractDomain } from "./helpers";
import "react-loading-skeleton/dist/skeleton.css";
import { CloudArrowUpIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { useUser } from "@clerk/clerk-react";
import useBase from "./useBase";
import { evaluate } from "mathjs";
import axios from "axios";
import Notification from "./Notification.js";
import { setShowNotif, setContents, } from "./helpers";
import { AppContext } from "./AppContext.js";
import { LinkIcon } from "@heroicons/react/24/outline";



function Icon({ id, open }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			strokeWidth={2}
		>
			<path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
		</svg>
	);
}

const getLargestGrants = (grants) => {
	if (grants) {
		return grants
			.sort((a, b) => a.fields.AnnualMargin - b.fields.AnnualMargin)
			.map((g) => `${g.fields.Name} (${currencyFormat(g.fields.AnnualMargin)})`)
			.slice(-3);
	}
};


export default function UpgradeList({
	admin,
	paid,
	userRecord,
	company,
	curUser,
	setCompany,
	flaggedGrants,
	setFlaggedGrants,
	interestedInGrants,
	setInterestedInGrants,
	eligibleGrants,
	companies,
	getQueryVariable,
	matchedDiscounts,
	grantWriters,
	isLoading,
	upgrades,
	industries,
	setTotalTechSavings,
	setSideBarOpen,
	setDisplayGrants,
	setDisplayCompanies,
	setDisplayPerks,
	setDisplayMyNetwork,
	techSavings,
	grantsSum,
	questions,
	answers,
	setTechPipeline
}) {

	let {
		showTechPipelineInfo,
		techScraperStatus,
		setShowTechPipelineInfo,
		setTechScraperStatus,
	} = useContext(AppContext);

	// const admin = Cookies.get("domain") === "all" && getQueryVariable(process.env.REACT_APP_QUERY_SECRET_1) === "true";
	const defaultCurrentMax = 25;
	const stepValue = 50;
	const navigate = useNavigate();
  const location = useLocation();

	const [grants, setGrants] = useState(eligibleGrants);
	const [open, setOpen] = useState(false);
	const [openNotInterested, setOpenNotInterested] = useState(false);
	const [openInterested, setOpenInterested] = useState(true);
	const [openGrants, setOpenGrants] = useState(true);
	const [currentMax, setCurrentMax] = useState(defaultCurrentMax);
	const [recommendedDiscounts, setRecommendedDiscounts] = useState([]);
	const [requestedTech, setRequestedTech] = useState([]);
	const [redeemedTech, setRedeemedTech] = useState([]);
	const [unblur, setUnblur] = useState(false);

	useEffect(() => {
		setRequestedTech(company?.fields?.RequestedTech ? company.fields.RequestedTech.split(',') : []);
		setRedeemedTech(company?.fields?.RedeemedTech ? company.fields.RedeemedTech.split(',') : []);

		if (admin || paid === "basic") {
			setUnblur(true);
		}
	}, [admin, paid, company]);

	// useEffect(() => {
	// 	if (companies) {
	// 		setCompany(companies?.filter((company) => company?.fields?.Domain == getQueryVariable("website"))[0]);
	// 	}
	// }, [companies]);

	useEffect(() => {
		if (matchedDiscounts.length !== 0) {
			let temp = [];
			for (let i = 0; i < matchedDiscounts.length; i++) {
				if (temp.filter((a) => a.fields.AssociatedTechnology === matchedDiscounts[i].fields.AssociatedTechnology).length === 0) {
					temp.push(matchedDiscounts[i]);
				}
			}
			setRecommendedDiscounts(temp);
			setTotalTechSavings(temp.reduce((partialSum, a) => partialSum + a?.fields?.AnnualMargin, 0));
		}
	}, [matchedDiscounts]);

	useEffect(() => {
		setGrants(eligibleGrants);
	}, [eligibleGrants]);

	const loadMore = () => {
		let currentLength = getNonFreebieGrants(grants).length;

		if (currentMax >= currentLength) return;

		setCurrentMax((prev) => prev + stepValue);
	};

	const loadLess = () => {
		if (currentMax <= stepValue) return;

		setCurrentMax((prev) => prev - stepValue);
	};

	const loadAll = () => {
		const maxLength = grants.length;
		setCurrentMax(maxLength);
	};
	
	async function callTechPipeline () {
		try{
		const eventSource = new EventSource('https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/techRefresh/listener');
    	eventSource.onmessage = function(event) {
		let data = JSON.parse(event.data)
		setTechScraperStatus(data.scraping) 
    };
}
		catch(error){
			console.log(`Error: ${JSON.stringify(error)}`)
		}
		try{
			setShowNotif(true, setTechPipeline);
			setContents(
			  { type: "success", heading: "Pipeline Initiated", message: `Your Newly Indexed Savings Are On Their Way! Please Refresh Your Page In Around 10 Minutes` }, // { type: "success", heading: "Pipeline Initiated", message: response.data },
			  setTechPipeline
		  ); 
		  const response = await axios.post(
			'https://murmuring-plateau-21252-b7994639367f.herokuapp.com/api/techRefresh',
		  );
		}
		catch(error){
			console.log(`Error: ${JSON.stringify(error)}`)
		}}

	const calculateUpgradeAmounts = (companyData, upgradeRecord) => {

		if (!companyData || !upgradeRecord) return undefined;

		let result = 0;
		const formula = upgradeRecord?.fields?.formula;
		const variables = upgradeRecord?.fields?.variables?.split(",")?.map((item) => item.trim());

		if (!formula || !variables) return undefined;

		let calculatedFormula = formula;

		variables.forEach((variable) => {
			const value = companyData[variable];

			if (value === undefined) return;

			calculatedFormula = calculatedFormula.replace(new RegExp(variable, "g"), value);
		});

		// Use mathjs to safely evaluate the calculated formula
		try {
			result = evaluate(calculatedFormula);
			// console.log(result, calculatedFormula);
		} catch (error) {
			console.error(`Error evaluating formula for upgrade:`, upgradeRecord, error);
			result = undefined;
		} finally {
			return result;
		}
	};

	const compareTopUpgradeAmounts = (upgradeA, upgradeB) => {
		if (!company?.fields) return 0;

		let amountA = calculateUpgradeAmounts(company?.fields, upgradeA);
		let amountB = calculateUpgradeAmounts(company?.fields, upgradeB);

		// console.log("amounts", amountA, amountB);

		if (amountA === undefined && amountB === undefined) return 0;
		else if (amountA === undefined) return 1;
		else if (amountB === undefined) return -1;

		return amountB - amountA;

	};

	// compareGrantsByOpenOrClosed : Grant, Grant ---> Int
	// Returns 0 if both grants are closed or if both grants are open
	// Returns Positive if A is Closed and B is not closed
	// Returns Negative if A is Open and B is closed
	const compareGrantsByOpenOrClosed = (grantRecordA, grantRecordB) => {
		let aClosed = grantRecordA?.fields?.OpenOrClosed?.trim()?.toLowerCase() === "closed";
		let bClosed = grantRecordB?.fields?.OpenOrClosed?.trim()?.toLowerCase() === "closed";

		if (aClosed && bClosed) return 0;
		else if (aClosed) return 1;
		else if (bClosed) return -1;
		else if (!aClosed && !bClosed) return 0;
	};

	const getFreebieGrants = (grantData) => {
		return grantData
			.filter((upgrade) => interestedInGrants.map((g) => g.fields.Name).includes(upgrade.fields.Name) === false)
			.filter((upgrade) => upgrade.fields.Favourite === "checked")
			.sort((a, b) => compareDates(a.fields.Deadline, b.fields.Deadline))
			.sort((a, b) => {
				if (isBadAmount(a?.fields?.AnnualMargin) && isBadAmount(b?.fields?.AnnualMargin)) return 0;
				else if (isBadAmount(a?.fields?.AnnualMargin)) return -1;
				else if (isBadAmount(b?.fields?.AnnualMargin)) return 1;
				else return 0;
			})
			.sort(compareGrantsByOpenOrClosed);
	};

	const getNonFreebieGrants = (grantData) => {
		return grantData
			.filter(
				(upgrade) =>
					(upgrade.fields.Favourite !== "checked")
			)
			.filter((upgrade) => interestedInGrants.map((g) => g.fields.Name).includes(upgrade.fields.Name) === false)
			.filter((upgrade) => flaggedGrants.map((g) => g.fields.Name).includes(upgrade.fields.Name) === false)
			.sort((a, b) => compareDates(a.fields.Deadline, b.fields.Deadline))
			.sort((a, b) => {
				if (isBadAmount(a?.fields?.AnnualMargin) && isBadAmount(b?.fields?.AnnualMargin)) return 0;
				else if (isBadAmount(a?.fields?.AnnualMargin)) return 1;
				else if (isBadAmount(b?.fields?.AnnualMargin)) return -1;
				else return 0;
			})
			.sort(compareGrantsByOpenOrClosed);
	};

	const getInterestedInGrants = (interestedGrantsArray) => {
		return interestedGrantsArray.sort(compareGrantsByOpenOrClosed);
	};

	const getTopUpgrades = (upgradesList) => {
		return upgradesList.sort(compareTopUpgradeAmounts);
	};

	 const toolTipMessage = (techScraperStatus) => {
		if (!techScraperStatus){
			return "Not Scraping!"

		}else if (techScraperStatus){
			return "Scraping!"
		}
		else 
		return "Don't Know!"
	}

	return (
			<div>
				<div id="vendor-savings"></div>
				<div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mb-2 z-10 p-4" id="">
					<div
						className="flex items-center gap-5">
						<h1 className="text-2xl font-semibold leading-6 text-gray-900">Vendor Savings</h1>
						<a href="#vendor-savings" className="text-sm font-semibold leading-6 text-indigo-500 pt-1"
						onClick={() => {
							setSideBarOpen(true);
						}}>
							Add +
						</a>
						<span
						onClick={() => {
							window.open(`https://docs.google.com/spreadsheets/u/1/d/1Jj5MpSRBB5oHh9z8FzbWj_BMbLKEqIRF/copy?gid=958046125#gid=958046125`);
						}}>
						<LinkIcon className="h-3 w-3 text-lg cursor-pointer" aria-hidden="true" />
						</span>
						

						<div className="relative ml-auto">
						{admin && (
							<button
							className="z-10 text-white shadow-sm bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg text-md px-4 py-1 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
							type="button"
							onMouseEnter={() => setShowTechPipelineInfo(true)}
							onMouseLeave={() => setShowTechPipelineInfo(false)}
							onClick={() => {
								callTechPipeline();
							}}
							>
							Add Vendors For Savings
							</button>
						)}

						{showTechPipelineInfo && (
							<div
							className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-56 rounded-lg bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5"
							onMouseEnter={() => setShowTechPipelineInfo(true)}
							onMouseLeave={() => setShowTechPipelineInfo(false)}
							>
							{techScraperStatus === false ? "Scraper Status: Not Scraping" : `Scraper Status: ${toolTipMessage(techScraperStatus) ?? "Unknown"}`}
							</div>
						)}
						</div>
					</div>
				</div>
				
				<div className="mt-6 border-t border-gray-100" id="">
					<div className="mx-auto max-w-7xl">
						<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
							<table className="w-full text-left">
								<tbody className="relative">
									{
										// Tech Savings - Sorted by Annual Margin
										isLoading && !company?.fields?.Technologies && recommendedDiscounts.length === 0
											? [1, 2, 3].map((id) => <SkelUpgradeCard key={id} />)
											: recommendedDiscounts
												.sort((a, b) => b.fields.AnnualMargin - a.fields.AnnualMargin)
												.map((upgrade) => {
													return (
														<Upgrade
															key={upgrade?.id}
															upgradeProp={upgrade}
															unblur={unblur || paid === 'tech'}
															fullBlur={!admin && paid === 'unpaid'}
															isLoading={isLoading}
															discounts={matchedDiscounts
																.filter((discount) => discount?.fields?.AssociatedTechnology === upgrade?.fields?.AssociatedTechnology)
																.sort((a, b) => b?.fields?.AnnualMargin - a?.fields?.AnnualMargin)}
															admin={admin}
															isRequested={requestedTech.includes(upgrade?.fields?.Name)}
															isRedeemed={redeemedTech.includes(upgrade?.fields?.Name)}
															company={company}
															setCompany={setCompany}
															userEmail={userRecord?.fields?.Email}
														/>
													);
												})
									}
									{!admin && paid === 'unpaid' && <button
										type="button"
										onClick={() => {
											const currentSearch = location.search;
											window.scrollTo({ top: 0 });
											navigate(`/pricing${currentSearch}`);
										}}
										class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
									>
										<LockClosedIcon className="mr-4 h-5 w-5 inline" /> Unlock {company?.fields ? currencyFormat(techSavings) + " In" : "These"} Vendor Savings
									</button>}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div id="grants"></div>

				<div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-20 z-10 p-4" id="">
					<div
						// onClick={() => {
						// 	window.open("https://airtable.com/appVulceDQ4SKlQoP/shraxj7a5RASvHOPy", '_blank') //Open a new page that leads to manual Grant form
						// }}
						onClick={() => {
							const currentSearch = location.search;
							window.scrollTo({ top: 0 });
							navigate(`/all-grants${currentSearch}`);
						}}
						className="flex items-center gap-2">
						<h1 className="text-2xl font-semibold leading-6 text-gray-900">Grants</h1>
						<a href="#vendor-savings" className="text-sm font-semibold leading-6 text-indigo-500 pt-1">
							Add +
						</a>
					</div>
				</div>

				<div className="mt-6 border-t border-gray-100" id="">
					<div className="mx-auto max-w-7xl">
						<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
							<div className="mt-1">
								<h3>
									<strong>Summary</strong>
								</h3>
								<p className="text-sm text-gray-700">
									<strong>{grants?.length}</strong> of <strong>2,887</strong> grants matched with {company?.fields?.Name}
								</p>
								<p className="text-sm text-gray-700">
									The largest grants & government programs are:{" "}
									{getLargestGrants(grants).map((item) => (
										<p className="flex">• {item}</p>
									))}
								</p>
							</div>

							{/* Interested in Grants Section */}
							<Fragment>
								<Accordion open={openInterested} icon={<Icon id={1} open={openInterested} />}>
									<AccordionHeader className="desktop:sticky top-[105px] md:top-16 bg-white mx-auto max-h-14 w-full mt-8 z-30 p-4" onClick={() => setOpenInterested((prev) => !prev)}>Grants We're Working on For You</AccordionHeader>
									<AccordionBody>
										<table className="w-full text-left">
											<tbody className="relative">
												<tr className="text-sm leading-6 text-gray-900">
													<th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
														{/* <span className="inline-flex">
															<CloudArrowUpIcon className="h-6 w-6 mt-1 mx-auto" />
															<time className="mt-1 px-2" dateTime={"today"}>
																Grants We're Working on For You
															</time>
														</span> */}
														{/* <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
														<div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" /> */}
													</th>
												</tr>
												{getInterestedInGrants(interestedInGrants).map((grant) => (
													<Upgrade
														key={grant.id}
														setGrants={setGrants}
														upgradeProp={grant}
														grantWriters={null}
														company={company}
														setCompany={setCompany}
														isGrant={true}
														industries={industries}
														flagged={false}
														interested={true}
														interestedInGrants={interestedInGrants}
														setInterestedInGrants={setInterestedInGrants}
														admin={admin}
														unblur={unblur}
														fullBlur={!admin && paid !== "basic"}
														showMissingInfoEmailToggle={unblur || admin}
														userEmail={userRecord?.fields?.Email}
														questions={questions}
														answers={answers}
													/>
												))}
												{!admin && paid !== "basic" && <button
													type="button"
													onClick={() => {
														const currentSearch = location.search;
														window.scrollTo({ top: 0 });
														navigate(`/pricing${currentSearch}`);
													}}
													class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
												>
													<LockClosedIcon className="mr-4 h-5 w-5 inline" /> Unlock {company?.fields ? currencyFormat(grantsSum) + " In" : "These"} Grants
												</button>}
											</tbody>
										</table>
									</AccordionBody>
								</Accordion>
							</Fragment>

							<Fragment>
								<Accordion open={openGrants} icon={<Icon id={1} open={openGrants} />}>
									<AccordionHeader 
										className="desktop:sticky top-[105px] md:top-16 bg-white mx-auto max-h-14 w-full mt-20 z-30 p-4 " 
										onClick={() => setOpenGrants((prev) => !prev)}>
											Grants For You
									</AccordionHeader>
									<AccordionBody>
										<table className="w-full text-left">
											<tbody className="relative">
												<tr className="text-sm leading-6 text-gray-900">
													<th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
														{/* <span className="inline-flex">
															<CloudArrowUpIcon className="h-6 w-6 mt-1 mx-auto" />
															<time className="mt-1 px-2" dateTime={"today"}>
																Grants For You
															</time>
														</span> */}
														{/* <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
														<div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" /> */}
													</th>
												</tr>
												{
													// Favourited Grants (Freebies) - Sorted by Deadline, all $0 grants at bottom however
													isLoading || !company?.fields?.Industry
														? [1, 2, 3].map((id) => <SkelUpgradeCard key={id} />)
														: getFreebieGrants(grants).map((upgrade) => (
															<Upgrade
																key={upgrade.id}
																setGrants={setGrants}
																upgradeProp={upgrade}
																admin={admin}
																grantWriters={grantWriters}
																company={company}
																industries={industries}
																isGrant={true}
																flaggedGrants={flaggedGrants}
																setFlaggedGrants={setFlaggedGrants}
																interestedInGrants={interestedInGrants}
																setInterestedInGrants={setInterestedInGrants}
																unblur={unblur}
																fullBlur={!admin && paid !== "basic"}
																userEmail={userRecord?.fields?.Email}
																questions={questions}
																answers={answers}
															/>
														))
												}
												{
													// Non-Favourited Grants (Blurred by Default) - Sorted by Deadline
													isLoading &&
														!(
															company?.fields?.Industry &&
															company?.fields?.Country &&
															company?.fields?.Address &&
															company?.fields?.Founded &&
															company?.fields?.Employees
														) ? (
														<SkelUpgradeCard key={1} />
													) : (
														getNonFreebieGrants(grants)
															.filter((upgrade, i) => {
																return i < currentMax;
															})
															.map((upgrade) => (
																<Upgrade
																	key={upgrade.id}
																	setGrants={setGrants}
																	upgradeProp={upgrade}
																	admin={admin}
																	grantWriters={grantWriters}
																	company={company}
																	industries={industries}
																	isGrant={true}
																	flagged={false}
																	flaggedGrants={flaggedGrants}
																	setFlaggedGrants={setFlaggedGrants}
																	interestedInGrants={interestedInGrants}
																	setInterestedInGrants={setInterestedInGrants}
																	unblur={unblur}
																	fullBlur={!admin && paid !== "basic"}
																	userEmail={userRecord?.fields?.Email}
																	questions={questions}
																	answers={answers}
																/>
															))
													)
												}
												{!admin && paid !== "basic" && <button
													type="button"
													onClick={() => {
														const currentSearch = location.search;
														window.scrollTo({ top: 0 });
														navigate(`/pricing${currentSearch}`);
													}}
													class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
												>
													<LockClosedIcon className="mr-4 h-5 w-5 inline" /> Unlock {company?.fields ? currencyFormat(grantsSum) + " In" : "These"} Grants
												</button>}
											</tbody>
										</table>
										<div className="items-center text-center mx-auto">
											{company && paid !== "basic" && currentMax > stepValue && (
												<button
													onClick={loadLess}
													className="mt-4 mr-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Show Less
												</button>
											)}
											{company && paid !== "basic" && getNonFreebieGrants(grants).length > currentMax && (
												<button
													onClick={loadMore}
													className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Show More
												</button>
											)}
											{company && paid !== "basic" && getNonFreebieGrants(grants).length > currentMax && (
												<button
													onClick={loadAll}
													className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Show All
												</button>
											)}
										</div>
									</AccordionBody>
								</Accordion>
							</Fragment>

							<Fragment>
								<Accordion open={openNotInterested} icon={<Icon id={1} open={openNotInterested} />}>
									<AccordionHeader className="desktop:sticky top-[105px] md:top-16 bg-white mx-auto max-h-14 w-full mt-20 z-30 p-4" onClick={() => setOpenNotInterested((prev) => !prev)}>Grants You're Not Interested In</AccordionHeader>
									<AccordionBody>
										<table className="w-full text-left">
											<tbody>
												<tr className="text-sm leading-6 text-gray-900">
													<th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
														{/* <span className="inline-flex">
															<CloudArrowUpIcon className="h-6 w-6 mt-1 mx-auto" />
															<time className="mt-1 px-2" dateTime={"today"}>
																Grants You're Not Interested In
															</time>
														</span> */}
														{/* <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
														<div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" /> */}
													</th>
												</tr>
												{flaggedGrants.map((grant) => (
													<Upgrade
														key={grant.id}
														setGrants={setGrants}
														upgradeProp={grant}
														admin={admin}
														grantWriters={null}
														company={company}
														flaggedGrants={flaggedGrants}
														setFlaggedGrants={setFlaggedGrants}
														isGrant={true}
														industries={industries}
														flagged={true}
														unblur={unblur}
														fullBlur={!admin && paid !== "basic"}
														userEmail={userRecord?.fields?.Email}
														questions={questions}
														answers={answers}
													/>
												))}
												{!admin && paid !== "basic" && openNotInterested && <button
													type="button"
													onClick={() => {
														const currentSearch = location.search;
														window.scrollTo({ top: 0 });
														navigate(`/pricing${currentSearch}`);
													}}
													class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white shadow-xl bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
												>
													<LockClosedIcon className="mr-4 h-5 w-5 inline" /> Unlock {company?.fields ? currencyFormat(grantsSum) + " In" : "These"} Grants
												</button>}
											</tbody>
										</table>
									</AccordionBody>
								</Accordion>
							</Fragment>
						</div>
					</div>
				</div>

				<div id="top-upgrades"></div>
				<div className="sticky top-[105px] md:top-16 bg-white mx-auto w-full mt-20 z-10 p-4" id="">
					<div
						onClick={() => {
							setSideBarOpen(true);
						}}
						className="flex items-center gap-2">
						<h1 className="text-2xl font-semibold leading-6 text-gray-900">Growth Upgrades (Prioritized by Your Data)</h1>
						<a href="#top-upgrades" className="text-sm font-semibold leading-6 text-indigo-500 pt-1">
							Edit +
						</a>
					</div>
				</div>
				<div className="mx-auto max-w-7xl" id="">
					<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
						<table className="w-full">
							<tbody className="">
								{/* <div class="absolute top-0 right-0">
									Obama balls
								</div> */}
								{getTopUpgrades(upgrades).map((upgrade) => (
									<Upgrade
										key={upgrade.fields.id}
										upgradeProp={upgrade}
										secret_1={admin}
										admin={admin}
										company={company}
										grantWriters={grantWriters}
										isUpgrade={true}
										infoIcon={"true"}
										unblur={unblur}
										userEmail={userRecord?.fields?.Email}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>

				{/* <Fragment>
				<Accordion open={open} icon={<Icon id={2} open={open} />}>
					<AccordionHeader onClick={() => setOpen(!open)}></AccordionHeader>
					<AccordionBody>
						<table className="w-full text-left">
							<tbody>
								<tr className="text-sm leading-6 text-gray-900">
									<th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
										<time dateTime={"today"}>Recommended Automations & Upgrades</time>
										<div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
										<div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
									</th>
								</tr>
								{upgrades.map((upgrade) => (
									<Upgrade
										key={upgrade.fields.id}
										upgradeProp={upgrade}
										secret_1={admin}
										company={company}
										grantWriters={grantWriters}
										isUpgrade={true}
										infoIcon={"false"}
									/>
								))}
							</tbody>
						</table>
					</AccordionBody>
				</Accordion>
			</Fragment> */}
			</div>
	);
}
