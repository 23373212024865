import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from 'react-loading-skeleton';
import {
  Bars3Icon,
  PhoneIcon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
  BoltIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  BuildingOffice2Icon,
  GiftIcon,
  TrophyIcon,
  IdentificationIcon,
  ArrowTrendingUpIcon,
  FolderIcon,
  GlobeAmericasIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import {
  ArrowUpCircleIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationTriangleIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/20/solid';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { UserButton, UserProfile, useUser } from '@clerk/clerk-react';
import Airtable from 'airtable';

import { AppContext } from './AppContext.js';
import Notification from './Notification.js';
import {
  classNames,
  getQueryVariable,
  setShowNotif,
  setContents,
  checkEmail,
  extractDomain,
  getWebURL,
  checkValidDomainName,
} from './helpers.js';
import Progress from './Progress.js';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const base = new Airtable({ apiKey: apiKey }).base(baseId);

const navigationNew = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: false, pro: false },
  {
    name: 'Company Info',
    href: '#company-info',
    icon: IdentificationIcon,
    current: false,
    pro: false,
  },
  {
    name: 'Vendor Savings',
    href: '#vendor-savings',
    icon: LightBulbIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Grants',
    href: '#grants',
    icon: CurrencyDollarIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Growth Upgrades',
    href: '#top-upgrades',
    icon: ArrowTrendingUpIcon,
    current: false,
    pro: false,
  },
  {
    name: 'Automations',
    href: '#automations',
    icon: BoltIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Financing',
    href: '#financing',
    icon: BanknotesIcon,
    current: false,
    pro: false,
  },
  {
    name: 'My Reports',
    href: '#myreports',
    icon: FolderIcon,
    current: false,
    pro: true,
  },
  {
    name: 'Onboarding',
    href: '#onboarding',
    icon: ClipboardDocumentListIcon,
    current: false,
    pro: false,
  },
];

const Layout = ({}) => {
  const curPath = useLocation();
  // Check if the current path is the root path
  const isRootPath = curPath.pathname === '/';

  const navigate = useNavigate();
  const location = useLocation();
  const [inviteOpen, setInviteOpen] = useState(false);
  const {
    company,
    companies,
    clerkUser,
    domainStatus,
    setDomainStatus,
    setOpen,
    loading,
    setLoading,
    grantLoading,
    setGrantLoading,
    JITWorking,
    setJITWorking,
    unknownDomain,
    setUnknownDomain,
  } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [emailStatus, setEmailStatus] = useState(
    checkEmail(email) ? 'correct' : email === '' ? 'empty' : 'incorrect'
  );
  const [domain, setDomain] = useState(extractDomain(''));
  const [admin, setAdmin] = useState(false);

  // Referral Notification
  const [emailModalNotif, setEmailModalNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  // Enrich Notification
  const [submitEnrichNotif, setSubmitEnrichNotif] = useState({
    showNotif: false,
    contents: { type: '', heading: '', message: '' },
  });

  const userNavigation = [
    {
      name: 'Company Info',
      href: `/company-info/${getQueryVariable('website')}`,
    },
    {
      name: 'Writer Network',
      href: `/successful-grant-writers`,
    },
    admin && {
      name: 'Add Questions',
      href: `/edit-grant-questions`,
    },
    admin && {
      name: 'Automation Requests',
      href: `/automation-requests`,
    },
    admin && {
      name: 'Financing Requests',
      href: `/financing-requests`,
    }
  ];

  useEffect(() => {
    if (!clerkUser) return;

    setEmail(clerkUser.primaryEmailAddress.emailAddress);
    if (clerkUser.primaryEmailAddress.emailAddress.split('@')[1] === process.env.REACT_APP_COOL_EMAIL) {
      setAdmin(true);
    }
    setDomain(extractDomain(clerkUser.primaryEmailAddress.emailAddress));
  }, [clerkUser]);

  function submitEmailInvite(email, comment, reportLink) {
    comment = comment + reportLink || 'Hey there lets see how this works!';

    var request = email + ' was just referred for savings ';

    setShowNotif(true, setEmailModalNotif);
    const content = {
      type: 'success',
      heading: `You just referred ${email}!`,
      message:
        "You'll be sent the greater of $500 or 15% of the first 12 months of revenue",
    };
    setContents(content, setEmailModalNotif);

    // https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/
    // fetch(`https://hooks.zapier.com/hooks/catch/13965335/3ztn14f/`, {
    //   method: 'POST',
    //   body: JSON.stringify({ request, email, comment }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {})
    //   .catch((error) => console.error(error));
  }

  const pushEmailtoAirtableLeads = async (email) => {
    base('leads').create(
      [
        {
          fields: {
            Email: email,
          },
        },
      ],

      function (err, records) {
        if (err) {
          console.error(err);
          return;
        }

        records.forEach(function (record) {
          console.log(record.getId());
        });
      }
    );
  };

  const handleSubmit = (domain) => {
    console.log('running');
    // Handle email
    if (domain?.includes('@')) {
      //If an email is detected, first add that to the leads table

      pushEmailtoAirtableLeads(domain);

      domain = domain?.split('@')[1]; // then split the email from the domain
    }

    // Handle website.
    if (
      domain?.startsWith('https://www.') ||
      domain?.startsWith('http://www.') ||
      domain?.startsWith('www.') ||
      domain?.startsWith('http://') ||
      domain?.startsWith('https://')
    ) {
      domain = domain.split('://')[1];

      if (domain?.includes('www.')) {
        domain = domain.split('www.')[1];
      }

      if (domain?.includes('/')) {
        domain = domain.split('/')[0];
      }
    }

    // Checking if the input submitted is a url
    const url = getWebURL(domain);
    if (url !== false) {
      domain = url;
    }

    // Creating the contents object for the notification
    const output = checkValidDomainName(domain);
    let contents = { type: '', heading: '', message: '' };

    console.log(output.result);

    if (output.result === 'Error') {
      contents.type = 'error';
      contents.heading = `Error in Fetching ${domain}❗`;
      contents.message = output.message;
    }
    // if the domain is valid (i.e. output.result==="Success")
    if (output.result === 'Success') {
      console.log('known', domainStatus);
      // If the company exists in airtable
      if (domainStatus === 'known') {
        const presentCompany = companies.filter(
          (company) => company.fields.Domain === domain
        )[0];
        window.location.href = `/?website=${domain}&company=${presentCompany.fields.Name}`;

        // if the company doesn't exist in airtable
      } else if (domainStatus === 'unknown') {
        // If the domain entered HAS been previously entered during this session
        // if (Cookies.get("enteredDomains")?.split(",").includes(domain)) {
        contents.type = 'success';
        contents.heading = 'Your Report is Being Upgraded';
        contents.message =
          "We're currently creating your report. It'll be emailed to you as soon as it's ready!";
        // } else {
        // -->  If the domain entered HAS NOT been entered previously in this session

        // Ensure enteredDomains is always an array
        //  const enteredDomains = Cookies.get("enteredDomains") ? Cookies.get("enteredDomains").split(",") : [];

        // Add the new domain to the array
        //  enteredDomains.push(domain);

        // Set the cookie with the updated array, converting it back to a comma-separated string
        //  Cookies.set("enteredDomains", enteredDomains.join(","), { expires: 1 });

        // Begin JIT report running for new domain
        window.location.href = `/?website=${domain}`;
        // }
      }
    }

    // If there is a notification to show
    if (contents.type != '') {
      setContents(contents, setSubmitEnrichNotif);
      setShowNotif(true, setSubmitEnrichNotif);
    }
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    console.log('domain changed', value);

    if (value === '') {
      setDomainStatus('');
      return;
    }

    // const possibleCompanies = companies.filter(
    //   (company) =>
    //     company?.fields?.Name?.trim().toLowerCase().replaceAll('.', '') ===
    //     value.trim().toLowerCase().replaceAll('.', '')
    // );
    // if (possibleCompanies.length > 0) {
    //   setDomainStatus('knownName');
    //   return;
    // }

    const output = checkValidDomainName(value);

    if (output.result === 'Error') {
      setDomainStatus('invalid');
      console.log('invalid domain!');
      return;
    }

    const companiesDomain = companies.filter(
      (company) =>
        company?.fields?.Domain?.toLowerCase() === value?.toLowerCase()
    );
    if (companiesDomain.length !== 0) {
      setDomainStatus('known');
    } else {
      setDomainStatus('unknown');
    }
  };

  function handleEmailChange(event) {
    const email = event.target.value;
    setEmailStatus(
      checkEmail(email) ? 'correct' : email === '' ? 'empty' : 'incorrect'
    );
    setEmail(email);
    setDomain(extractDomain(email));
  }

  return (
    <div>
      <Progress
        loading={grantLoading}
        JITWorking={JITWorking}
        unknownDomain={unknownDomain}
      />
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="h-8 w-auto cursor-pointer"
              src="https://assets.website-files.com/634f60226f66af42a384d5b7/6356df5890197b8e776cffc2_upgraded-logos-p-500.jpg"
              alt="Upgraded"
              id="logo"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-2">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigationNew.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        onClick={() => {
                          const currentSearch = location.search;
                          navigate(`/${currentSearch}`);
                        }}
                        className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <item.icon
                          className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                        {item.pro === true ? (
                          <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                            Pro
                          </span>
                        ) : (
                          ''
                        )}
                      </a>
                    </li>
                  ))}
                  {/* need admin */}
                  {/* {admin ? ( */}
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    More
                  </div>
                  <li key="companies" className="cursor-pointer">
                    <button
                      onClick={() => {
                        const currentSearch = location.search;
                        window.scrollTo({ top: 0 });
                        navigate(`/companies${currentSearch}`);
                      }}
                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                    >
                      <BuildingOffice2Icon
                        className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      Companies
                    </button>
                  </li>
                  {/* ): null} */}
                  <li key="perks" className="cursor-pointer">
                    <button
                      onClick={() => {
                        const currentSearch = location.search;
                        window.scrollTo({ top: 0 });
                        navigate(`/perks${currentSearch}`);
                      }}
                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                    >
                      <GiftIcon
                        className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      Perks
                      <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                        Pro
                      </span>
                    </button>
                  </li>

                  {/* need admin */}
                  {/* {admin ? ( */}
                  <li key="network" className="cursor-pointer">
                    <button
                      onClick={() => {
                        const currentSearch = location.search;
                        window.scrollTo({ top: 0 });
                        navigate(`/my-network${currentSearch}`);
                      }}
                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                    >
                      <GlobeAmericasIcon
                        className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      My Network
                      <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                        Pro
                      </span>
                    </button>
                  </li>
                  {/* <li key="grant-network" className="cursor-pointer">
                    <button
                      onClick={() => {
                        const currentSearch = location.search;
                        window.scrollTo({ top: 0 });
                        navigate(`/successful-grant-writers${currentSearch}`);
                      }}
                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                    >
                      <UserGroupIcon
                        className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      Grant Network
                      <span className="inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">
                        Pro
                      </span>
                    </button>
                  </li> */}
                  {/* ): null} */}
                  <li key="allGrants" className="cursor-pointer">
                    <button
                      onClick={() => {
                        const currentSearch = location.search;
                        window.scrollTo({ top: 0 });
                        navigate(`/all-grants${currentSearch}`);
                      }}
                      className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                    >
                      <MagnifyingGlassIcon
                        className="text-gray-400 hover:text-indigo-600 hover:bg-gray-50 h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      All Grants
                    </button>
                  </li>
                </ul>
              </li>

              {/* Run Savings Report*/}
              <div className="mt-auto">
                <div className="mb-1">
                  <div className="flex justify-between cursor-pointer hover:text-indigo-600 hover:bg-gray-200 rounded -px-1 py-1">
                    <label
                      htmlFor="email"
                      className=" inline-flex block text-sm font-medium leading-6 text-gray-900 "
                    >
                      Invite
                    </label>
                    <span
                      className="text-sm leading-6 text-gray-500 cursor-pointer"
                      id="email-optional"
                      onClick={() => {
                        setInviteOpen(true);
                      }}
                    >
                      <GiftIcon
                        className="text-gray-400 h-5 w-5 shrink-0 inline pb-1"
                        aria-hidden="true"
                      />{' '}
                      Refer & Earn
                    </span>
                  </div>
                </div>

                <div className="flex justify-between">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 pb-1"
                  >
                    New Savings Report
                  </label>
                </div>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    const domain = event.target['enrichCompanyInput'].value;
                    handleSubmit(domain);
                  }}
                  className="mt-auto relative flex flex-grow items-stretch max-h-[40px]"
                >
                  <input
                    type="website"
                    name="enrichCompany"
                    id="enrichCompanyInput"
                    className={`text-sm block w-full rounded-none rounded-l-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:outline-none focus:ring-2 focus:ring-inset ${
                      domainStatus !== 'invalid'
                        ? 'focus:ring-indigo-600'
                        : 'focus:ring-red-600'
                    }
                    `}
                    placeholder="yourfriendswebsite.com"
                    onKeyDown={(event) => {
                      if (
                        event.key === 'Enter' &&
                        submitEnrichNotif.showNotif === false
                      ) {
                        event.preventDefault();
                        const domain = event.target.value;
                        handleSubmit(domain);
                      }
                    }}
                    onChange={handleCompanyChange}
                    disabled={loading}
                  />

                  <button
                    type="submit"
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    // disabled={reqSavingsNotif.showNotif}
                  >
                    <BoltIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="black"
                    />
                  </button>
                </form>
              </div>
              <button
                class="bg-emerald-600 group hover:bg-white text-white font-semibold hover:text-emerald-600 py-2 px-4 border border-emerald-600 rounded"
                onClick={() =>
                  window.open(
                    'https://www.getupgraded.ca/free-savings-consultation',
                    '_blank'
                  )
                }
              >
                <PhoneIcon
                  className="h-5 w-6 text-white group-hover:text-emerald-600 inline pb-1 mr-2"
                  aria-hidden="true"
                />
                Improve Profitability
              </button>
            </ul>
          </nav>
        </div>
      </div>
      <div className="lg:pl-72 overflow-visible">
        <header className="drop-shadow-md sticky top-0 z-30 flex h-16 shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            // onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <form
              className="relative flex flex-1"
              action="#"
              method="GET"
            ></form>

            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* Upgrade Button */}
              <button
                type="button"
                className="rounded-md bg-green-500 shadow-xl px-3 py-2 text-sm font-semibold text-white hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-700"
                onClick={() => {
                  const currentSearch = location.search;
                  window.scrollTo({ top: 0 });
                  navigate(`/pricing${currentSearch}`);
                }}
              >
                Upgrade
              </button>

              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">View notifications</span>
                <UserButton afterSignOutUrl="/sign-in" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  {!Boolean(company?.fields?.LogoUrl) ? (
                    <span className="inline-block mb-3 rounded-full">
                      <Skeleton circle={true} height={36} width={36} />
                    </span>
                  ) : company?.fields?.LogoUrl ? (
                    <img
                      className="inline-block h-8 w-8 rounded-md mr-1"
                      src={company.fields.LogoUrl}
                      alt=""
                    />
                  ) : getQueryVariable('website') ? (
                    <img
                      className="inline-block h-8 w-8 rounded-md mr-1"
                      src={`https://logo.clearbit.com/${getQueryVariable(
                        'website'
                      )}`}
                      alt=""
                    />
                  ) : (
                    <Skeleton circle={true} height={36} width={36} />
                  )}
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-50 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            rel="noopener noreferrer"
                            href={item.href}
                            target="_blank"
                            onClick={null}
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* HAMBURGER */}
              {isRootPath && (
                <div className="lg:block inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <button
                    className="lg:block margin-left pb-[0.5] hover:shadow-gray-600 focus-visible:outline"
                    onClick={() => setOpen(true)}
                  >
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
        <div className="lg:hidden sticky top-14 mt-2 z-20 h-14 shrink-0 items-center gap-x-4 bg-white border-b px-4 pb-1 pt-2 sm:gap-x-6 sm:px-6 lg:px-8">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const domain = event.target['enrichCompanyInput'].value;
              handleSubmit(domain);
            }}
            className="mt-auto relative flex flex-grow items-stretch max-h-[40px]"
          >
            <input
              type="website"
              name="enrichCompany"
              id="enrichCompanyInput"
              className={`text-sm block w-full rounded-none rounded-l-md border-0 py-1.5 px-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:outline-none focus:ring-2 focus:ring-inset ${
                domainStatus != 'invalid'
                  ? 'focus:ring-indigo-600'
                  : 'focus:ring-red-600'
              }`}
              placeholder="yourfriendswebsite.com"
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  submitEnrichNotif.showNotif === false
                ) {
                  event.preventDefault();
                  const domain = event.target.value;
                  handleSubmit(domain);
                }
              }}
              onChange={handleCompanyChange}
              disabled={loading}
            />

            <button
              type="submit"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              disabled={submitEnrichNotif.showNotif}
            >
              <BoltIcon className="h-5 w-5" aria-hidden="true" fill="black" />
            </button>
          </form>
        </div>
        <main>
          <Outlet />
        </main>
      </div>
      <Transition.Root show={inviteOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setInviteOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      const email = event.target['email'].value;
                      const comment = event.target['comment'].value;
                      const reportLink = event.target['reportLink'].value;
                      // console.log(comment);
                      submitEmailInvite(email, comment, reportLink);
                    }}
                    className="space-y-6"
                  >
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <GiftIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h1"
                        className="text-lg font-semibold leading-6 text-gray-900"
                      >
                        Earn $500 or 15% over 12 months
                        <br />
                        for every new company you refer
                      </Dialog.Title>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">
                          Invite someone who can benefit from nearly-free money
                          and you’ll both earn a $500 USD credit when they
                          become a member.
                          <br />
                          <br />
                          Some referrees earn over $2,000 / mo.
                        </p>
                      </div>
                      <br />
                      <div className="text-left mt-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Invite with email
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          onChange={(e) => {
                            handleEmailChange(e);
                          }}
                          autoComplete="email"
                          required
                          value={email}
                          placeholder="yourfriend@company.com"
                          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${
                            emailStatus === 'incorrect' ? 'red' : 'indigo'
                          }-600 sm:text-sm sm:leading-6`}
                        />
                        <p className="text-red-800 ml-1 mb-2 text-sm">
                          {emailStatus === 'incorrect'
                            ? 'Invalid email, please try again'
                            : ''}
                        </p>
                      </div>
                      <div className="mt-2 text-left">
                        <label
                          htmlFor="comment"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Include a message (optional)
                        </label>
                        <div>
                          <textarea
                            rows={6}
                            name="comment"
                            id="comment"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="Hi there,

                                I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis.
                                
                                They do a give-get program so that we each get $500 off.
                                
                                They've done over $10m in grants, vendor savings and automations at an 80% success rate and your report shows you have over $100,000 in savings.
                                
                                Kindly see this link to your report below.
                                
                                Hope you're doing well!"
                          />
                        </div>
                      </div>
                      <div className="text-left mt-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Savings Report Link (appended to your email)
                        </label>
                        <div>
                          <input
                            type="text"
                            name="reportLink"
                            id="reportLink"
                            disabled
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                            placeholder={`https://app.getupgraded.ca/?website=${domain}`}
                            value={`https://app.getupgraded.ca/?website=${domain}`}
                            aria-describedby="email-description"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="submit"
                        disabled={emailStatus !== 'correct'}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          setDomain('');
                          setInviteOpen(false);
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Notification
        showNotif={emailModalNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setEmailModalNotif)}
        contents={emailModalNotif.contents}
      />
      <Notification
        showNotif={submitEnrichNotif.showNotif}
        setShowNotif={(val) => setShowNotif(val, setSubmitEnrichNotif)}
        contents={submitEnrichNotif.contents}
      />
    </div>
  );
};

export default Layout;
