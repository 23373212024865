import React, { useEffect, useState } from "react";
import { currencyFormat, checkValidDomainName } from "./helpers";
import { Tooltip } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AreaChart, Card, Title, Metric, Text } from "@tremor/react";

export default function Summary({
	// submitEnrich,
	reqSavingsNotif,
	grantsSum,
	isLoading,
	isGrantsLoading,
	companies,
	company,
	find_grants,
	findDiscounts,
	getQueryVariable,
	techSavings,
}) {
	const [loading, setLoading] = useState(true);
	const stats_skeleton_height = 115; // height used for skeleton loading for stats / savings pillars
	const graph_skeleton_height = 370; // height used for skeleton loading for graph

	const [sum, setSum] = useState(grantsSum);

	const [domainStatus, setDomainStatus] = useState("");

	useEffect(() => {
		setSum(grantsSum);
	}, [grantsSum]);

	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	const chartdata = !company ? [] : [
		{
			date: "July 24",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (10 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (10 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (65 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (30 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (30 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (67.5 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (47.5 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (47.5 / 55)),
		},
		{
			date: "Oct 24",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (70 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (50 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (50 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (72.5 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (47.5 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (47.5 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (75 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (55 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (55 / 55)),
		},
		{
			date: "Jan 25",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (77.5 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (60 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (60 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (80 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (67.5 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (67.5 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (82.5 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (65 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (65 / 55)),
		},
		{
			date: "Apr 25",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (85 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (70 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (70 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (87.5 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (75 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (75 / 55)),
		},
		{
			date: "",
			"Status Quo": Math.round((company.fields?.AnnualRevenue / 12) * (90 / 55)),
			"Revenue Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (80 / 55)),
			"Savings Upgrades": Math.round((company.fields?.AnnualRevenue / 12) * (80 / 55)),
		},
	];

	const valueFormatter = function (number) {
		return "$ " + new Intl.NumberFormat("us").format(number).toString();
	};

	return (
		<div className="relative isolate overflow-hidden pt-4">
			<div className="flex flex-col items-center w-full p-6 pb-6 rounded-lg shadow-xl sm:p-8 m-auto">
				<h2 className="text-xl font-bold w-full">
					<h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">{company?.fields?.Name} Monthly Revenue</h3>
					{loading ? (
						<Skeleton height={40} width={100} />
					) : (
						<p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
							{currencyFormat(
								company.fields?.AnnualRevenue ? company.fields?.AnnualRevenue / 12 : (company.fields.Employees * 100000) / 12
							)}
						</p>
					)}
					{loading || !company ? (
						<Skeleton height={graph_skeleton_height} />
					) : (
						<AreaChart
							className="mt-1 h-72 w-[100%]"
							data={chartdata}
							index="date"
							yAxisWidth={95}
							stack={true}
							categories={["Status Quo", "Revenue Upgrades", "Savings Upgrades"]}
							colors={["indigo", "cyan", "emerald"]}
							valueFormatter={valueFormatter}
						/>
					)}
					<div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mt-5">
						{loading || !company ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" decorationColor="cyan">
								<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Vendor Savings Available</p>
								<p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company?.fields ? currencyFormat(techSavings) : "Coming Soon"}
								</p>
							</Card>
						)}
						{isGrantsLoading || !company ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" decorationColor="emerald">
								<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Grants Available</p>
								<p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company?.fields ? currencyFormat(sum) : "Coming Soon"}
								</p>
							</Card>
						)}

						{loading || !company ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" decorationColor="stone">
								<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Automations & Ai Available</p>
								<p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company && company.fields ? currencyFormat(company.fields.Employees * 20000) : "Coming Soon"}
								</p>
							</Card>
						)}

						{loading || !company ? (
							<Skeleton height={stats_skeleton_height} />
						) : (
							<Card className="mx-auto max-w-xs" decoration="top" decorationColor="indigo">
								<p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Financing Available</p>
								<p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
									{company && company.fields && company.fields.AnnualRevenue
										? currencyFormat(
												company?.fields?.AnnualRevenue * 0.7
													? company.fields.AnnualRevenue * 0.7 - (company.fields.Debt || 0)
													: company.fields.Employees * 100000 - (company.fields.Debt || 0)
										  )
										: "Coming Soon"}
								</p>
							</Card>
						)}
					</div>
				</h2>
				{/* <span class="text-sm font-semibold text-gray-500">{new Date().toLocaleString("en-US", { month: "long" })} 2023</span> */}
			</div>
			{/* Stats */}
		</div>
	);
}
