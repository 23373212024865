import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	setContents,
	setShowNotif,
	getFillerText,
	currencyFormat,
	splitAndAddLineBreaks,
	getDateColor,
	dateToString,
	classNames,
	checkDateFormat,
	isBadAmount,
	getHiddenSummary,
	shortenText,
	extractDomain,
} from "./helpers";

import { getQuestionsToDisplay, handleAutodraftGrant } from "./Autodraft.js";
import Notification from "./Notification";
import SkelUpgradeCard from "./SkelUpgradeCard";
import EditGrantModal from "./EditGrantModal.js";
import { countryCodes, statuses } from "./helperData.js";
import {
	ArrowUpCircleIcon,
	ArrowRightIcon,
	XMarkIcon,
	AdjustmentsVerticalIcon,
	HeartIcon,
	PlusCircleIcon,
	ArrowDownCircleIcon,
	VariableIcon,
	CheckCircleIcon,
	CursorArrowRaysIcon,
	StarIcon
} from "@heroicons/react/20/solid";
import { Accordion, AccordionHeader, AccordionBody, Tooltip, Switch } from "@material-tailwind/react";
import GrantsInterestedInDialogue from "./GrantsInterestedInDialogue.js";
import {
	TrashIcon,
	ArrowTopRightOnSquareIcon,
	CheckBadgeIcon,
	CalendarDaysIcon,
	CurrencyDollarIcon,
	ClipboardDocumentCheckIcon,
	CalendarIcon,
	PhoneIcon,
	QuestionMarkCircleIcon,
	TrophyIcon,
	IdentificationIcon
} from "@heroicons/react/24/outline";
import DeleteGrantDialog from "./DeleteGrantDialog.js";
import SendReferralEmailDialog from "./SendReferralEmailDialog.js";
import TechRequest from "./TechRequest.js";
import RedeemDialog from "./RedeemDialog.js";
import { evaluate } from "mathjs";
import SendAutoDraftModal from "./SendAutoDraftModal.js";

function Upgrade({
	upgradeProp,
	setGrants,
	fullBlur,
	grantWriters,
	company,
	setCompany,
	industries,
	infoIcon,
	discounts,
	isLoading,
	isGrant,
	isUpgrade,
	flagged,
	flaggedGrants,
	setFlaggedGrants,
	interested,
	interestedInGrants,
	setInterestedInGrants,
	admin,
	unblur,
	showMissingInfoEmailToggle,
	isRequested,
	isRedeemed,
	userEmail,
	questions,
	answers
}) {
	const [upgrade, setUpgrade] = useState(upgradeProp);
	const [interestedHistory, setInterestedHistory] = useState(interestedInGrants)
	const isFavourite = upgradeProp?.fields?.Favourite === "checked";

	const badAmount = isBadAmount(upgradeProp?.fields?.AnnualMargin);
	const badAmountMessage = "(Coming Soon) ";

	const maxInterestedGrants = 20;
	const maxSumaryLength = 180;

	const baseId = process.env.REACT_APP_AIR_BASEID;
	const grantTableId = process.env.REACT_APP_AIR_GRANTS;
	const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;
	const apiKey = process.env.REACT_APP_AIR_KEY;

	const [recommendedDiscount, setRecommendedDiscount] = useState({});
	const [otherDiscounts, setOtherDiscounts] = useState([]);
	const [openOtherSavings, setOpenOtherSavings] = useState(false);
	const [showDiscountDetails, setShowDiscountDetails] = useState(false);
	const [editGrant, setEditGrant] = useState(false);
	const [openEditGrant, setOpenEditGrant] = useState(false);
	const [showInterestedInDialogue, setShowInterestedInDialogue] = useState(false);
	const [openDeleteGrantDialog, setOpenDeleteGrantDialog] = useState(false);
	const [loadingReferralEmails, setLoadingReferralEmails] = useState(false);
	const [openReferralDialog, setOpenReferralDialog] = useState(false);
	const [openTechRequest, setOpenTechRequest] = useState(false);
	const [openRedeemDialog, setOpenRedeemDialog] = useState(false);
	const [sendMissingInfoToggle, setSendMissingInfoToggle] = useState(null);
	const [loadingMissingInfoToggle, setLoadingMissingInfoToggle] = useState(false);
	const [redeemed, setRedeemed] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);


	useEffect(() => {
		if (discounts === undefined || discounts.length === 0) return;
		setRecommendedDiscount(discounts[0]);
		setOtherDiscounts(discounts.slice(1));
	}, [discounts]);

	/* ----------------------------------------------

	Notification State + Notification Functions

  ------------------------------------------------- */

	const [showReferralNotification, setShowReferralNotification] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	const [updatedNotif, setUpdatedNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	const [techNotif, setTechNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	/* ----------------------------------------------

	Update Grants + Airtable Function

  ------------------------------------------------- */

	// updateGrantFeature is a sort of generic function which will update whichever field of a certain grant with a new value.
	//   feature : string --> feature should be exactly one of the fields of upgrade objects ("Technologies", "Amount", "Deadline", etc.)
	//   recordId : string --> The recordId record in our database
	//   newValue : string | number | boolean -> the new value for the field
	const updateGrantFeature = (feature, recordId, newValue) => {
		if (["Eligible_Industry", "Eligible_Region", "Amount", "Deadline"].includes(feature) === false) return;

		fetch("https://api.airtable.com/v0/" + baseId + "/" + grantTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					[feature]: newValue,
				},
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				if (Object.keys(data).includes("error")) {
					setContents(
						{
							type: "error",
							heading: typeof data.error === "object" && Object.keys(data.error).includes("type") ? data.error.type : "Error",
							message: typeof data.error === "object" && Object.keys(data.error).includes("message") ? data.error.message : data.error,
						},
						setUpdatedNotif
					);
				} else {
					setContents(
						{
							type: "success",
							heading: `Saved to Airtable`,
							message: `${upgrade?.fields?.Name.trim()}'s ${feature} field has been changed from "${upgrade?.fields[feature]
								}" to "${newValue}"`,
						},
						setUpdatedNotif
					);
				}

				setShowNotif(true, setUpdatedNotif);
				setUpgrade((prev) => ({ id: prev.id, recordId: prev.recordId, fields: { ...prev.fields, [feature]: newValue } }));
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const flagGrant = (grant) => {
		let recordId = company.id;
		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					ExcludeGrants: [...flaggedGrants.map((g) => g?.fields?.Name), grant.fields.Name].join(","),
				},
			}),
		})
			.then((resp) => console.log(resp))
			.catch((err) => console.error(err));

		setFlaggedGrants((prev) => [...prev, grant]);
	};

	const unflagGrant = (grant) => {
		let recordId = company.id;

		if (flaggedGrants.filter((g) => g.fields.Name === grant.fields.Name).length === 0) {
			console.log("not flagged");
			return;
		}

		let newFlaggedGrants = flaggedGrants.filter((g) => g.fields.Name != grant?.fields?.Name);
		setFlaggedGrants(newFlaggedGrants);

		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					ExcludeGrants: newFlaggedGrants.map((g) => g.fields.Name).join(","),
				},
			}),
		})
			.then((resp) => console.log(resp))
			.catch((err) => console.error(err));
	};

	const addGrantToInterested = (grant) => {
		try {
			if (interestedInGrants.length >= maxInterestedGrants) {
				setShowInterestedInDialogue(true);
				return;
			}
		} catch (error) {
			console.log("interestedingrants empty!")
			return
		}

		let recordId = company.id;

		if (interestedInGrants.map((g) => g.fields.id).includes(grant.fields.id)) {
			console.log("Already recognized as interested!");
			return;
		}

		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					InterestedIn: [...interestedInGrants.map((g) => g?.fields?.Name), grant.fields.Name].join(","),
				},
			}),
		})
			.then((resp) => console.log("Response", resp))
			.catch((err) => console.error("Error in Added Interested Grant: ", err));

		const isDuplicate = interestedHistory.some(item => item.id === grant.id);
		if (!isDuplicate) {
			setInterestedHistory(prev => [...prev, grant]);
		}
		setInterestedInGrants((prev) => [...prev, grant]);

		// prevent us from logging duplicates
		if (isDuplicate) return;

		// const request = `${userEmail} just requested for a Grant Saving for ${company?.fields?.Name}!`
		// const website = `Grant Upgrade: ${currencyFormat(upgrade?.fields?.AnnualMargin)}/yr ${upgrade?.fields?.Name}`

		// fetch(`https://hooks.zapier.com/hooks/catch/13965335/${process.env.REACT_APP_ZAPIER_SAVINGS}/`, {
		// 	method: "POST",
		// 	body: JSON.stringify({ request, website }),
		// });

		function joinArrayElements(array) {
			// Filter out empty or undefined elements
			const filteredArray = array.filter(element => element !== '' && element !== undefined);
			// Join the non-empty elements with commas
			const joinedString = filteredArray.join(', ');
			return joinedString;
		}

		console.log("empty?", grantWriters?.slice(upgrade.fields.id, 5 + upgrade.fields.id));
		const partnerEmails = joinArrayElements(grantWriters?.slice(upgrade.fields.id, 5 + upgrade.fields.id));

		fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
			method: "POST",
			body: JSON.stringify({
				CompanyName: company?.fields?.Name,
				ClientEmail: userEmail,
				RequesterEmail: userEmail,
				PartnerEmail: '',
				UpgradeName: upgrade?.fields?.Name,
				Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
				ClientName: '',
				Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
				UpgradeType: "Grant",
				Deadline: upgrade?.fields?.Deadline,
				Password: "",
			})
		})
	};

	const removeGrantFromInterested = (grant) => {
		let recordId = company.id;

		if (interestedInGrants.filter((g) => g.fields.Name === grant.fields.Name).length === 0) {
			console.log("Grant not contained in interested in?");
			return;
		}

		let newInterestedIn = interestedInGrants.filter((g) => g.fields.Name != grant?.fields?.Name);
		setInterestedInGrants(newInterestedIn);

		fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + recordId, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${apiKey}`,
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				fields: {
					InterestedIn: newInterestedIn.map((g) => g.fields.Name).join(","),
				},
			}),
		})
			.then((resp) => console.log(resp))
			.catch((err) => console.error(err));
	};

	const handleReferralClick = async () => {
		setOpenReferralDialog(true);
	};

	const handleTechClick = () => {
		setOpenTechRequest(true);
	}

	const handleRedeemClick = () => {
		setOpenRedeemDialog(true);
	}

	//                    //
	// Autodraft Function //
	//                    //
	function handleAutodraftSubmit(company, upgrade) {
		// console.log(company.fields.LongDescription);
		// console.log(upgrade.fields.Eligibility_Criteria);
		// console.log("this is a test!");

		fetch("https://hooks.zapier.com/hooks/catch/13965335/3kc1op9/", {
			method: "POST",
			body: JSON.stringify({
				companyName: company.fields.Name,
				companyData: company.fields.LongDescription,
				grantName: upgrade.fields.Name,
				grantQuestions: upgrade.fields.Eligibility_Criteria,
			}),
		});
	}

	const [showModal, setShowModal] = useState(false);
	const [isMobileView, setIsMobileView] = useState(false);

	const calculateUpgradeAmounts = (data, upgrade) => {
		let result = 0;

		if (!upgrade?.fields?.formula || !upgrade?.fields?.variables) return;

		const formula = upgrade?.fields?.formula;
		let calculatedFormula = formula;

		if (Boolean(upgrade?.fields?.variables?.split(", ")) === false) return;
		upgrade.fields?.variables?.split(", ")?.forEach((variable) => {
			const value = data[variable];
			if (value === undefined) {
				return;
			}

			calculatedFormula = calculatedFormula.replace(new RegExp(variable, "g"), value);
		});

		// Use mathjs to safely evaluate the calculated formula
		try {
			result = evaluate(calculatedFormula);
			return result;
		} catch (error) {
			console.error(`Error evaluating formula for upgrade:`, upgrade, error);
			return undefined;
		}
	};

	const showCalculation = (data, upgrade) => {
		let result = "";
		// console.log(upgrade);
		if (upgrade.fields.formula && upgrade.fields.variables) {
			const formula = upgrade.fields.formula;
			let calculatedFormula = formula;

			// Substitute variables in the formula with actual values from the data
			// console.log(upgrade.fields.variables.split(", "));
			// console.log(upgrade.fields.variables);
			// console.log(data);

			upgrade.fields.variables.split(", ").forEach((variable) => {
				const value = data[variable];
				if (value === undefined) {
					return;
				}

				calculatedFormula = calculatedFormula.replace(new RegExp(variable, "g"), value);
			});

			// Use mathjs to safely evaluate the calculated formula
			// try {
			// 	result[upgrade.upgradeType] = evaluate(calculatedFormula);
			// } catch (error) {
			// 	console.error(`Error evaluating formula for ${upgrade.upgradeType}:`, error);
			// }
			// console.log(calculatedFormula);
			result = calculatedFormula;
		}
		return result;
	};

	const data = {
		ConversionRate: 2,
		engineeringSalaries: 100000,
		rdPercentage: 20,
		taxCreditRate: 0.15,
		AvgDealSize: 5000,
		AnnualTraffic: 12000,
		AnnualRevenue: 100,
		OperationalCosts: 50,
		Employees: 15,
		ListSize: 10,
	};

	const rules = [
		{
			fields: {
				upgradeType: "R&D Tax Credit",
				variables: ["engineeringSalaries", "rdPercentage", "taxCreditRate"],
				formula: "(engineeringSalaries * rdPercentage / 100) * taxCreditRate",
			},
		},
		{
			fields: {
				upgradeType: "Paid Ads Revenue",
				variables: ["dealSize", "conversionRate", "traffic"],
				formula: "(dealSize * (traffic * conversionRate / 100))",
			},
		},
	];

	const addGrantToMissingInfoList = async (upgradeRecordId) => {
		try {
			const currentMissingInfoList = company.fields.MissingInfoFor?.split(",")?.map((item) => item.trim());
			if (!Boolean(currentMissingInfoList) || !currentMissingInfoList.includes(upgradeRecordId)) {
				let newMissingInfoList = [];
				if (!Boolean(currentMissingInfoList)) newMissingInfoList = [upgradeRecordId];
				else if (!currentMissingInfoList.includes(upgradeRecordId)) newMissingInfoList = [...currentMissingInfoList, upgradeRecordId];

				setLoadingMissingInfoToggle(true);

				const response = await fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + company.id, {
					method: "PATCH",
					headers: {
						Authorization: `Bearer ${apiKey}`,
						"Content-Type": "application/json",
					},

					body: JSON.stringify({
						fields: {
							MissingInfoFor: newMissingInfoList.join(","),
						},
					}),
				});

				console.log(await response.json());
				setCompany((prev) => ({ ...prev, fields: { ...prev.fields, MissingInfoFor: newMissingInfoList.join(",") } }));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingMissingInfoToggle(false);
		}
	};

	const getCategoryValue = (upgrade) => {
		const category = upgrade?.fields?.Category;
		if (category && typeof category === 'object' && 'value' in category) {
			return category.value;
		} else if (typeof category === 'string') {
			return category.trim();
		}
		return undefined;
	};

	const removeGrantFromMissingInfoList = async (upgradeRecordId) => {
		try {
			const currentMissingInfoList = company.fields?.MissingInfoFor?.split(",")?.map((item) => item.trim());
			if (!Boolean(currentMissingInfoList) || !currentMissingInfoList.includes(upgradeRecordId)) return;

			const newMissingInfoList = currentMissingInfoList.filter((item) => item !== upgradeRecordId);

			setLoadingMissingInfoToggle(true);
			const response = await fetch("https://api.airtable.com/v0/" + baseId + "/" + companiesTableId + "/" + company.id, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: {
						MissingInfoFor: newMissingInfoList.join(","),
					},
				}),
			});

			setCompany((prev) => ({ ...prev, fields: { ...prev.fields, MissingInfoFor: newMissingInfoList.join(",") } }));
			console.log(await response.json());
		} catch (error) {
			console.error(error);
			// error notifications
		} finally {
			setLoadingMissingInfoToggle(false);
		}
	};

	const handleMissingInfoToggle = async (checked) => {
		setSendMissingInfoToggle(checked);

		// console.log("handle function", checked);

		if (checked) {
			await addGrantToMissingInfoList(upgrade.recordId);
		} else {
			await removeGrantFromMissingInfoList(upgrade.recordId);
		}
	};

	// Initializing the default value of sendMissingInfoToggle based on whether upgrade.id is contained in MissingInfoFor csv list.
	useEffect(() => {
		if (!company || !upgradeProp) return;
		if (sendMissingInfoToggle !== null) return;

		let sendMissingInfoToggleValue = Boolean(
			company?.fields?.MissingInfoFor?.split(",")
				?.map((item) => item.trim())
				?.includes(upgrade.recordId)
		);

		setSendMissingInfoToggle(sendMissingInfoToggleValue);
	}, [company, sendMissingInfoToggle, upgrade, upgradeProp]);

	useEffect(() => {
		const checkViewport = () => {
			setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint according to your needs
		};

		// Check viewport initially and add event listener for changes
		checkViewport();
		window.addEventListener("resize", checkViewport);

		// Clean up the event listener when the component is unmounted
		return () => {
			window.removeEventListener("resize", checkViewport);
		};
	}, []);

	return isMobileView ? (
		<>
			<tr key={upgrade.fields.Id} className={fullBlur ? "blurred pointer-events-none" : ""}>
				<td className="relative py-5 pr-6 max-w-[500px]">
					<div className="flex gap-x-3">
						<ArrowUpCircleIcon className="hidden h-6 w-6 flex-none sm:block text-xl mt-3" aria-hidden="true" />
						<div className="flex-auto">
							<div className="flex items-start gap-x-3">
								<div
									className={"text-lg font-medium leading-6 hover:underline"}
									onClick={() => {
										if (!isGrant) return;
										setShowModal(true);
									}}
								>
									{upgrade?.fields?.formula &&
										upgrade?.fields?.variables &&
										Boolean(company) &&
										Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
										? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)) + "/yr "
										: badAmount
											? badAmountMessage
											: currencyFormat(upgrade?.fields?.AnnualMargin) + "/yr "}{" "}
									<span className={
										classNames(
											(unblur || isFavourite || isUpgrade) &&
												(upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur
												? ""
												: "blurred")}
									>
										{(unblur || isFavourite || isUpgrade) && (upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur ? upgrade?.fields?.Name : getFillerText(upgrade?.fields?.Name?.length)}
									</span>
								</div>
								<div>{upgrade?.fields?.Verified && <CheckBadgeIcon className="-ml-1.5 h-5 w-auto text-blue-500" aria-hidden="true" />}</div>
								<div
									className={classNames(statuses[upgrade?.fields?.Function], "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset")}
								>
									{upgrade?.fields?.Function}
								</div>
							</div>
							<div className="mt-1 text-md leading-5 text-gray-500 max-w-[650px]">
								{(unblur || isFavourite || isUpgrade) &&
									(upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur
									? shortenText(upgrade?.fields?.Description_2, maxSumaryLength)
									: shortenText(
										getHiddenSummary(upgrade?.fields?.Description_2, upgrade?.fields?.Name, upgrade?.fields?.Function),
										maxSumaryLength
									)}
								{/* <div className={upgrade?.fields?.Deadline ? getDateColor(upgrade?.fields.Deadline) : "text-gray-400"}>
									<strong>{upgrade?.fields?.Deadline ? dateToString(upgrade?.fields.Deadline) : ""}</strong>
								</div> */}
							</div>
						</div>
					</div>
					<div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
					<div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />

					<div className="text-sm leading-6 text-gray-900">
						{/* Upgrade #{upgrade?.id + 1} */}
						{upgrade?.fields.Technology_Upgrade || infoIcon == "false" ? (
							""
						) : (
							<button
								className="inline-flex items-center justify-center w-10 h-10 mr-2 text-indigo-100 transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-black-100 relative top-1.5"
								type="button"
								onClick={() => setShowModal(true)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									className="w-6 h-6 text-black"
								>
									<path
										strokeLinecap="round"
										stroke-linejoin="round"
										d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
									/>
								</svg>
							</button>
						)}
						{/* To Open Edit Grants Modal */}
						{/* {admin && isGrant && (
							<button
								className="inline-flex items-center justify-center mr-2 text-black focus:shadow-outline relative top-1.5"
								type="button"
								onClick={() => setOpenEditGrant((prev) => !prev)}
							>
								<AdjustmentsVerticalIcon className="h-6 w-6 text-gray-black hover:text-indigo-700" />
							</button>
						)} */}
					</div>
					{/* <div className={"mt-1 text-xs leading-5 text-gray-500" + (!unblur && !isFavourite ? " blurred" : "")}>
						<Tooltip content="The best matched grant writers for your company & grant" placement="bottom" className="inline">
							<div className="-space-x-1 overflow-hidden">
								{grantWriters?.slice(upgrade.fields.id, 5 + upgrade.fields.id).map((grantwriter) => (
									<img
										key={`${upgrade.recordId}`}
										className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
										src={grantwriter.fields.PictureUrlCopy}
										alt=""
									/>
								))}
							</div>
						</Tooltip>
					</div> */}
					{showModal ? (
						<>
							<div className="w-screen justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative my-6 max-h-full max-w-lg p-8">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative max-h-full flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-sm font-semibold">

												{unblur === true || isFavourite ?

													<button
														onClick={() => {
															window.open(`${upgrade.fields.GrantUrl}`);
														}}
														className="items-center justify-center flex"
													>
														<Tooltip placement="top" content="Get Us Started On This Grant">
															<ArrowTopRightOnSquareIcon aria-hidden className="h-5 w-5 rounded-xl hover:text-indigo-400" />
														</Tooltip>
													</button>
													: getFillerText(upgrade.fields.GrantUrl)}
												<span className={
													classNames(
														(unblur || isFavourite || isUpgrade) &&
															(upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur
															? ""
															: "blurred")}
												>
													{(unblur || isFavourite || isUpgrade) && (upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur ? upgrade?.fields?.Name : getFillerText(upgrade?.fields?.Name?.length)}
												</span>
												- {upgrade?.fields?.formula ? (upgrade?.fields?.formula &&
													upgrade?.fields?.variables &&
													Boolean(company) &&
													Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
													? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)) + "/yr "
													: badAmount
														? badAmountMessage
														: currencyFormat(upgrade?.fields?.AnnualMargin) + "/yr") : currencyFormat(upgrade?.fields.AnnualMargin)}
												<br />
												{upgrade?.fields?.formula ? (
													<span className="inline-flex">
														Estimated Time Required: <p className={classNames("ml-1")}>{upgrade?.fields?.TTV} days</p>
													</span>
												) :
													// <span className="inline-flex">
													// 	Deadline To Apply: <p className={classNames("ml-1")}>{upgrade?.fields?.Deadline}</p>
													// </span>
													null
												}
											</h3>
											<button
												onClick={() => setShowModal((prev) => !prev)}
												className="p-1 ml-auto text-3xl leading-none font-semibold outline-none focus:outline-none"
											>
												<XMarkIcon className="text-black h-6 w-6 hover:text-indigo-700" />
											</button>
										</div>
										{/*body*/}
										<div className="relative p-6 flex-auto">
											{upgrade?.fields?.formula
												? (
													<p className="text-sm leading-relaxed">
														{/* {upgrade.fields.Eligible_Region} | {upgrade.fields.Eligible_Industry} | {upgrade.fields.Eligible_Employees} | {upgrade.fields.Eligible_Revenue} | {upgrade.fields.Eligible_Founded} */}
														<h5 className="font-bold">Summary</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{upgrade?.fields?.Subtitle}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Requirement</h5>
														<span>
															{upgrade?.fields["Qualification Criteria"] ? upgrade?.fields["Qualification Criteria"] : "N/A"}
														</span>
														<br />
														<br />
														{upgrade?.fields?.Description && (
															<>
																<h5 className="font-bold">Notes</h5>
																<p className={unblur === false && isFavourite === false ? " blurred" : ""}>
																	{unblur === false && isFavourite === false
																		? getFillerText(upgrade.fields.Description?.length)
																		: upgrade.fields.Description}
																</p>
																<br />
															</>
														)}
														<h5 className="font-bold">Success Rate</h5>
														<span className="">
															{upgrade?.fields?.psuccess * 100}%
														</span>
														<br />
														<br />
														<h5 className="font-bold">Min Spend</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															${upgrade?.fields?.MinSpend}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Derivation of Amount</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															= {(upgrade?.fields?.formula &&
																upgrade?.fields?.variables &&
																Boolean(company) &&
																Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
																? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade))
																: badAmount
																	? badAmountMessage
																	: currencyFormat(upgrade?.fields?.AnnualMargin))}
															<br />
															= {upgrade?.fields?.formula}
															<br />
															= {company ? showCalculation(company?.fields, upgrade) : showCalculation(data, upgrade)}
															<br />
															{unblur === true || isFavourite ? upgrade.fields.GrantUrl : getFillerText(upgrade.fields.GrantUrl)}
														</span>
														{/* {admin && <br />}
														{admin && <br />}
														{admin && <h5 className="font-bold">Scraped Info</h5>}
														{admin && company && company.fields.LongDescription} */}
													</p>
												)
												: (
													<p className="text-sm leading-relaxed">
														{/* {upgrade.fields.Eligible_Region} | {upgrade.fields.Eligible_Industry} | {upgrade.fields.Eligible_Employees} | {upgrade.fields.Eligible_Revenue} | {upgrade.fields.Eligible_Founded} */}
														<h5 className="font-bold">Summary</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Description_2
																: getFillerText(upgrade.fields.Description_2?.length)}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Required Information</h5>
														<span>
															{upgrade.fields.Eligibility_Criteria ? (
																<div
																	dangerouslySetInnerHTML={{
																		__html: splitAndAddLineBreaks(upgrade.fields.Eligibility_Criteria),
																	}}
																/>
															) : (
																""
															)}
														</span>
														<br />
														{upgrade?.fields?.Description && (
															<>
																<h5 className="font-bold">Description</h5>
																<p className={unblur === false && isFavourite === false ? " blurred" : ""}>
																	{unblur === false && isFavourite === false
																		? getFillerText(upgrade.fields.Description?.length)
																		: upgrade.fields.Description}
																</p>
																<br />
															</>
														)}
														<h5 className="font-bold">Eligible Activities</h5>
														<span className="">
															{upgrade.fields.Eligible_Activities?.split("\\n").map((item) => (
																<>
																	{item}
																	<br />
																</>
															))}
														</span>
														<br />
														<h5 className="font-bold">Eligible Applicants</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Eligible_Applicants
																: getFillerText(upgrade.fields.Eligible_Applicants?.length)}
														</span>
														<br />
														<br />
														<h5 className="font-bold">More Info</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Funding_Amount
																: getFillerText(upgrade.fields.Funding_Amount?.length)}
															<br />
															{unblur === true || isFavourite ? upgrade.fields.GrantUrl : getFillerText(upgrade.fields.GrantUrl)}
														</span>
														{/* {admin && <br />}
														{admin && <br />}
														{admin && <h5 className="font-bold">Scraped Info</h5>}
														{admin && company && company.fields.LongDescription} */}
													</p>
												)}
										</div>
										{/*footer*/}
										<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
											{/* {admin && company && upgrade && (
												<button
													className="bg-gray-900 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
													type="button"
													onClick={() => {
														handleAutodraftSubmit(company, upgrade);
													}}
												>
													Autodraft
												</button>
											)} */}
											<button
												className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={() => setShowModal(false)}
											>
												Close
											</button>
											<button
												className="bg-gray-900 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={() => setShowModal(false)}
											>
												Done
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : null}
				</td>
			</tr>
			<tr className={fullBlur ? "blurred" : ""}>
				<td className="text-center"></td>
			</tr>
		</>
	) : (
		<>
			<tr key={upgrade.id} className={classNames((interested ? " " : "") + (fullBlur ? " blurred pointer-events-none" : ""))}>
				<td
					onClick={() => {
						console.log("my-upgrade", upgrade);
						if (admin && upgrade?.fields?.Technology_Upgrade === true) setShowDiscountDetails((prev) => !prev);
					}}
					className="relative pt-5 pb-5 pr-6 max-w-[800px]"
				>
					<div className="flex gap-x-3">
						<div className="">
							<div>
								{company && interested === false && flagged === false ? (
									<Tooltip content="Interested in This Upgrade?" placement="right">
										<button onClick={() => addGrantToInterested(upgrade)}>
											<ArrowUpCircleIcon
												className="hidden h-6 w-6 flex-none sm:block text-xl mt-1 hover:fill-green-300"
												aria-hidden="true"
											/>
										</button>
									</Tooltip>
								) : company && interested === true ? (
									<Tooltip content="Want Us to Reprioritize For You?" placement="right">
										<button onClick={() => removeGrantFromInterested(upgrade)}>
											<ArrowDownCircleIcon
												className="hidden h-6 w-6 fill-gray-300 flex-none sm:block text-xl mt-3 hover:fill-orange-300"
												aria-hidden="true"
											/>
										</button>
									</Tooltip>
								) : (
									<ArrowUpCircleIcon className="hidden h-6 w-6 flex-none sm:block text-xl mt-3" aria-hidden="true" />
								)}
							</div>

							{company && flaggedGrants && flagged === false ? (
								<Tooltip content="Not Relevant to You?" placement="right">
									<button onClick={() => flagGrant(upgrade)}>
										<XMarkIcon
											className="hidden h-6 w-6 fill-gray-300 hover:fill-red-300 flex-none sm:block text-xl mt-2"
											aria-hidden="true"
										/>
									</button>
								</Tooltip>
							) : company && flaggedGrants && flagged === true ? (
								<Tooltip content="Want this upgrade?" placement="right">
									<button onClick={() => unflagGrant(upgrade)}>
										<PlusCircleIcon
											className="hidden h-6 w-6 fill-black hover:fill-green-300 flex-none sm:block text-xl mt-2"
											aria-hidden="true"
										/>
									</button>
								</Tooltip>
							) : (
								<></>
							)}
						</div>
						<div className="flex-auto">
							<div className="flex items-start gap-x-3">
								<div className="items-start justify-start gap-x-3">
									<div className="inline-flex flex-col items-start justify-start gap-x-3">
										<div
											type="button"
											onClick={() => {
												if (!isGrant && !isUpgrade) return;
												setShowModal((prev) => !prev);
											}}
											className={classNames(
												"text-lg font-bold leading-6",
												isGrant || isUpgrade ? "hover:underline hover:cursor-pointer" : "",
												"inline-flex"
											)}
										>
											<p data-testid="upgrade title">
												{upgrade?.fields?.formula &&
													upgrade?.fields?.variables &&
													Boolean(company) &&
													Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
													? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)) + "/yr "
													: badAmount
														? badAmountMessage
														: currencyFormat(upgrade?.fields?.AnnualMargin) + "/yr "}{" "}
												<span
													className={classNames(
														(unblur || isFavourite || isUpgrade) &&
															(upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur
															? ""
															: "blurred"
													)}
												>
													{(unblur || isFavourite || isUpgrade) && (upgrade?.fields?.Technology_Upgrade === isRequested || admin) && !fullBlur
														? upgrade?.fields?.Name
														: getFillerText(upgrade?.fields?.Name?.length)}
												</span>
											</p>
											{/* Verified Check Badge */}
											{/* ToDo 
												1) DONE - Add the fields in the DB - questions, sample answers
												2) IN PROGRESS - Add the ability to edit the fields on the grant form below verified
												3) DONE - Add the conditional logic below 
												4) DONE - Test that it works

												DFM modal
												5) DONE - Add modal to work on click
												6) DONE - Edit zap to send email to right person
												7) Add the email to the Slack notification
												8) Change email copy: add $ amount i.e. "$50,000", add % success
											*/}
											<span>
												{upgrade?.fields?.Verified && (
													<Tooltip content="Grant info correct" placement="top">
														<CheckBadgeIcon className="ml-1 -mt-0.5 w-4 h-auto text-blue-500 inline" aria-hidden="true" />
													</Tooltip>
												)}
												{/* Questions */}
												{admin && upgrade?.fields?.QuestionsComplete && (
													<Tooltip content="Questions uploaded" placement="top">
														<QuestionMarkCircleIcon className="ml-1 -mt-0.5 w-4 h-auto inline" aria-hidden="true" />
													</Tooltip>
												)}
												{/* Sample Answers */}
												{admin && upgrade?.fields?.SampleAnswersComplete && (
													<Tooltip content="Successful grant answers uploaded" placement="top">
														<TrophyIcon className="ml-1 -mt-0.5 w-4 h-auto inline" aria-hidden="true" />
													</Tooltip>
												)}
												{/* Company Info */}
												{admin && company?.fields?.LongDescription && (
													<Tooltip content="Company info uploaded" placement="top">
														<IdentificationIcon className="ml-1 -mt-0.5 w-4 h-auto inline" aria-hidden="true" />
													</Tooltip>
												)}
											</span>
											<span
												className={classNames(statuses[upgrade?.fields?.GrantOrLoan ?? upgrade.fields.Function], "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset ml-1")}
											>
												{(upgrade?.fields?.GrantOrLoan ?? upgrade.fields.Function) || "No Info"}
											</span>
											{
												(upgrade?.fields?.Category?.value ?? upgrade?.fields?.Category) ? (
													<span
														className={classNames(
															statuses[getCategoryValue(upgrade)],
															"rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset ml-1"
														)}
													>
														{getCategoryValue(upgrade)}
													</span>
												) : null
											}
											{admin && isGrant && setGrants && !interested && !flagged && (
												<span className="items-center text-center justify-content">
													<button onClick={() => setOpenDeleteGrantDialog((prev) => !prev)}>
														<TrashIcon className="h-4 w-auto ml-1 -mt-1 text-gray-900 hover:text-red-400 inline" aria-hidden />
													</button>
												</span>
											)}
										</div>
									</div>
									<div className="row-below">
										{isGrant && upgrade?.fields?.OpenOrClosed?.toLowerCase()?.trim() === "closed" && (
											<span
												className={classNames(
													statuses["Sales"],
													"rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
												)}
											>
												Temp. Closed
											</span>
										)}
									</div>
								</div>
							</div>
							<div className="mt-1 text-md leading-5 text-gray-500 max-w-[650px]">
								{upgrade?.fields?.Technology_Upgrade ? (
									<p className="text-md">
										Because <strong className={(unblur || isFavourite) &&
											(upgrade?.fields?.Technology_Upgrade === isRequested || admin) ? "" : "blurred"}>{(unblur || isFavourite) && (upgrade?.fields?.Technology_Upgrade === isRequested || admin) ? upgrade?.fields?.AssociatedTechnology : getFillerText(upgrade?.fields?.AssociatedTechnology.length)}</strong> is a
										part of your tech stack
									</p>
								) : (
									""
								)}
								{unblur || isFavourite
									? shortenText(upgrade?.fields?.Description_2, maxSumaryLength)
									: shortenText(
										getHiddenSummary(upgrade?.fields?.Description_2, upgrade?.fields?.Name, upgrade?.fields?.Function),
										maxSumaryLength
									)}
								{upgrade?.fields?.Subtitle ? upgrade?.fields?.Subtitle : ""}
								{upgrade?.fields?.formula ?
									<div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
										<div className="mt-2 flex items-center text-xs text-gray-500">
											<CalendarDaysIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-00" aria-hidden="true" />
											{upgrade?.fields?.TTV} days
										</div>
										<div className="mt-2 flex items-center text-xs text-gray-500">
											<CurrencyDollarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
											${upgrade?.fields?.MinSpend} min spend
										</div>
										<div className="mt-2 flex items-center text-xs text-gray-500">
											<ClipboardDocumentCheckIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
											{upgrade?.fields?.psuccess * 100}%
										</div>
										<div className="mt-2 flex items-center text-xs text-gray-500">
											<CheckBadgeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
											30 day money back
										</div>
									</div>
									: ""}
								{/* EXPECTED IMPACT FORMULA */}
								{/* {upgrade?.fields?.formula ?
									<div className="mt-2 flex items-center text-xs text-gray-500">
										<VariableIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" /> {upgrade?.fields?.formula} | {company ? showCalculation(company?.fields, upgrade) : showCalculation(data, upgrade)}
									</div>
									: ""} */}

								{/* Debug Block <br /><br />	
								Insert Variables Here - {upgrade?.fields?.Inputs} <br /><br />
								Insert Formula Here - {upgrade?.fields?.formula} <br /><br />
								Insert New Variables Here - {upgrade?.fields?.variables} <br /><br />
								Insert Values Here - {upgrade?.fields?.Inputs} <br /><br />
								Insert Formula Here - {upgrade?.fields?.ImpactCalculation} <br /><br />
								Insert Result here - {company ? calculateUpgradeAmounts(company?.fields, upgrade) : calculateUpgradeAmounts(data, upgrade)}<br /><br />
								Print company data here - {JSON.stringify(company?.fields)} */}
								{/* <div className={upgrade?.fields?.Deadline ? getDateColor(upgrade.fields.Deadline) : "text-gray-400"}>
									<strong>{upgrade?.fields?.Deadline ? dateToString(upgrade.fields.Deadline) : ""}</strong>
								</div> */}


								{/* DEADLINE GOES HERE */}
								{isGrant &&
									<div className={upgrade?.fields?.Deadline ? getDateColor(upgrade.fields.Deadline) : "text-gray-400"}>
										<strong>Tentative Deadline: {upgrade?.fields?.Deadline ? dateToString(upgrade.fields.Deadline) : ""}</strong>
									</div>
								}
							</div>
						</div>
					</div>
					<div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
					<div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
				</td>

				<td className="hidden py-5 pr-2 sm:table-cell">
					<span className="text-sm mx-2 leading-6 gap-x-4 text-gray-900 font-medium">
						{/* Upgrade #{upgrade?.id + 1} */}
						{upgrade?.fields?.Technology_Upgrade || infoIcon == "false" ? (
							""
						) : (
							<button
								className="rounded bg-white px-2 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
								type="button"
								onClick={() => setShowModal(true)}
							>
								More Info
							</button>
						)}
						{admin && isGrant && (
							<button
								className="inline-flex items-center justify-center mx-2 text-black focus:shadow-outline relative top-1.5"
								type="button"
								onClick={() => setOpenEditGrant((prev) => !prev)}
							>
								<AdjustmentsVerticalIcon className="h-6 w-6 text-gray-black hover:text-indigo-700" />
							</button>
						)}
					</span>
					<span className="relative top-1.5">
						{Boolean(company) && interested && showMissingInfoEmailToggle && (
							<Tooltip content="Receive updates on missing information" placement="top" offset={15}>
								<div className="items-center inline mt-2">
									<input
										id={`sendMissingInfoToggle_${upgrade.recordId}`}
										type="checkbox"
										value={sendMissingInfoToggle}
										checked={sendMissingInfoToggle}
										onChange={async (e) => await handleMissingInfoToggle(e.target.checked)}
										className="hidden"
									/>
									<label
										htmlFor={`sendMissingInfoToggle_${upgrade.recordId}`}
										className="relative inline-flex items-center cursor-pointer"
									>
										<span className="sr-only">Send Missing Info Toggle</span>
										<span
											className={`w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 peer-focus:ring-2 peer-focus:ring-blue-300 transition-colors duration-200 ease-in-out ${sendMissingInfoToggle ? "bg-green-500" : "bg-gray-200"}`}
										></span>
										<span
											className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 ease-in-out transform ${sendMissingInfoToggle ? "translate-x-5" : "translate-x-0"}`}
										></span>
									</label>
								</div>

							</Tooltip>
						)}
					</span>
					{/* <div className="mt-1 px-2 mr-2 text-xs leading-5 text-gray-500">
						<Tooltip content="The best matched grant writers for your company & grant" placement="bottom" className="inline">
							<div className="flex -space-x-1 overflow-hidden mx-auto">
								{grantWriters?.slice(upgrade.fields.id, 5 + upgrade.fields.id).map((grantwriter) => (
									<img
										key={`${grantwriter.fields.Name}_${upgrade.id}`}
										className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
										src={grantwriter.fields.PictureUrlCopy}
										alt=""
									/>
								))}
							</div>
						</Tooltip>
					</div> */}

					{showModal && (
						<>
							<div className="pt-20 justify-center items-center flex overflow-x-hidden overflow-y-auto h-px-4000 max-h-full fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative w-auto my-6 mx-auto max-h-full max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative max-h-full flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-sm font-semibold">
												<span className={classNames(isFavourite || unblur || isUpgrade ? "" : " blurred ")}>
													{unblur === true || isFavourite ? upgrade?.fields.Name : getFillerText(upgrade?.fields.Name?.length)}
												</span>
												- {upgrade?.fields?.formula ? (upgrade?.fields?.formula &&
													upgrade?.fields?.variables &&
													Boolean(company) &&
													Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
													? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)) + "/yr "
													: badAmount
														? badAmountMessage
														: currencyFormat(upgrade?.fields?.AnnualMargin) + "/yr") : currencyFormat(upgrade?.fields.AnnualMargin)}
												{" "}{unblur === true || isFavourite ?
													<button
														onClick={() => {
															window.open(`${upgrade.fields.GrantUrl}`);
														}}
														className=""
													>
														<ArrowTopRightOnSquareIcon aria-hidden className="h-4 w-4 rounded-xl hover:text-indigo-400" />
													</button>
													: getFillerText(upgrade.fields.GrantUrl)}
												<br />
												{upgrade?.fields?.formula ? (
													<span className="inline-flex">
														Estimated Time Required: <p className={classNames("ml-1")}>{upgrade?.fields?.TTV} days</p>
													</span>
												) :
													// <span className="inline-flex">
													// 	Deadline To Apply: <p className={classNames("ml-1")}>{upgrade?.fields?.Deadline}</p>
													// </span>
													null
												}
											</h3>
											<button
												onClick={() => setShowModal((prev) => !prev)}
												className="p-1 ml-auto text-3xl leading-none font-semibold outline-none focus:outline-none"
											>
												<XMarkIcon className="text-black h-6 w-6 hover:text-indigo-700" />
											</button>
										</div>
										{/*body*/}
										<div className="relative p-6 flex-auto">
											{upgrade?.fields?.formula
												? (
													<p className="text-sm leading-relaxed">
														{/* {upgrade.fields.Eligible_Region} | {upgrade.fields.Eligible_Industry} | {upgrade.fields.Eligible_Employees} | {upgrade.fields.Eligible_Revenue} | {upgrade.fields.Eligible_Founded} */}
														<h5 className="font-bold">Summary</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{upgrade?.fields?.Subtitle}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Requirement</h5>
														<span>
															{upgrade?.fields["Qualification Criteria"] ? upgrade?.fields["Qualification Criteria"] : "N/A"}
														</span>
														<br />
														<br />
														{upgrade?.fields?.Description && (
															<>
																<h5 className="font-bold">Notes</h5>
																<p className={unblur === false && isFavourite === false && !isUpgrade ? " blurred" : ""}>
																	{unblur === false && isFavourite === false && !isUpgrade
																		? getFillerText(upgrade.fields.Description?.length)
																		: upgrade.fields.Description}
																</p>
																<br />
															</>
														)}
														<h5 className="font-bold">Success Rate</h5>
														<span className="">
															{upgrade?.fields?.psuccess * 100}%
														</span>
														<br />
														<br />
														<h5 className="font-bold">Min Spend</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															${upgrade?.fields?.MinSpend}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Derivation of Amount</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															= {(upgrade?.fields?.formula &&
																upgrade?.fields?.variables &&
																Boolean(company) &&
																Boolean(currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade)))
																? currencyFormat(calculateUpgradeAmounts(company?.fields, upgrade))
																: badAmount
																	? badAmountMessage
																	: currencyFormat(upgrade?.fields?.AnnualMargin))}
															<br />
															= {upgrade?.fields?.formula}
															<br />
															= {company ? showCalculation(company?.fields, upgrade) : showCalculation(data, upgrade)}
															<br />
															{unblur === true || isFavourite ? upgrade.fields.GrantUrl : getFillerText(upgrade.fields.GrantUrl)}
														</span>
														{/* {admin && <br />}
														{admin && <br />}
														{admin && <h5 className="font-bold">Scraped Info</h5>}
														{admin && company && company.fields.LongDescription} */}
													</p>
												)
												: (
													<p className="text-sm leading-relaxed">
														{/* {upgrade.fields.Eligible_Region} | {upgrade.fields.Eligible_Industry} | {upgrade.fields.Eligible_Employees} | {upgrade.fields.Eligible_Revenue} | {upgrade.fields.Eligible_Founded} */}
														<h5 className="font-bold">Summary</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Description_2
																: getFillerText(upgrade.fields.Description_2?.length)}
														</span>
														<br />
														<br />
														<h5 className="font-bold">Required Information</h5>
														<span>
															{upgrade.fields.Eligibility_Criteria ? (
																<div
																	dangerouslySetInnerHTML={{
																		__html: splitAndAddLineBreaks(upgrade.fields.Eligibility_Criteria),
																	}}
																/>
															) : (
																""
															)}
														</span>
														<br />
														{upgrade?.fields?.Description && (
															<>
																<h5 className="font-bold">Description</h5>
																<p className={unblur === false && isFavourite === false ? " blurred" : ""}>
																	{unblur === false && isFavourite === false
																		? getFillerText(upgrade.fields.Description?.length)
																		: upgrade.fields.Description}
																</p>
																<br />
															</>
														)}
														<h5 className="font-bold">Eligible Activities</h5>
														<span className="">
															{upgrade.fields.Eligible_Activities?.split("\\n").map((item) => (
																<>
																	{item}
																	<br />
																</>
															))}
														</span>
														<br />
														<h5 className="font-bold">Eligible Applicants</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Eligible_Applicants
																: getFillerText(upgrade.fields.Eligible_Applicants?.length)}
														</span>
														<br />
														<br />
														<h5 className="font-bold">More Info</h5>
														<span className={isFavourite || unblur === true || isUpgrade ? "" : "blurred"}>
															{unblur === true || isFavourite
																? upgrade.fields.Funding_Amount
																: getFillerText(upgrade.fields.Funding_Amount?.length)}
															<br />
															{/* {unblur === true || isFavourite ? upgrade.fields.GrantUrl : getFillerText(upgrade.fields.GrantUrl)} */}

														</span>
														{/* {admin && <br />}
														{admin && <br />}
														{admin && <h5 className="font-bold">Scraped Info</h5>}
														{admin && company && company.fields.LongDescription} */}
													</p>
												)}
										</div>
										{/*footer*/}
										<div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
											{/* {admin && company && upgrade && (
												<button
													className="bg-gray-900 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
													type="button"
													onClick={() => {
														handleAutodraftSubmit(company, upgrade);
													}}
												>
													Autodraft
												</button>
											)} */}
											<button
												className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={() => setShowModal(false)}
											>
												Close
											</button>
											<button
												className="bg-gray-900 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
												type="button"
												onClick={() => setShowModal(false)}
											>
												Done
											</button>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					)}
				</td>

				{/* Button for InterestedIn Grants -- Links to the grants-form */}
				{company && interested && upgrade.recordId && (
					<td>
						<div className="flex text-center items-center justify-center mr-4">
							<button
								onClick={() => {
									window.open(`${window.location.origin}/grants-form/${company.id}/${upgrade.recordId}`);
								}}
								className="items-center justify-center flex"
							>
								<Tooltip placement="top" content="Get Us Started On This Grant">
									<ArrowTopRightOnSquareIcon aria-hidden className="h-6 w-6 rounded-xl text-gray-400 hover:text-indigo-400" />
								</Tooltip>
							</button>
						</div>
					</td>
				)}

				{/* Referral Introduction Partner Email (RIPE) Button */}
				{company && (
					<td className="py-5 px-2 text-right min-w-[200px]">
						<Tooltip
							className="inline"
							placement="top"
							content={(redeemed || isRedeemed) ? "Your discount has been redeemed, you can try redeeming again if you wish" : `Let us get your company started on this ${isGrant ? "grant." : isUpgrade ? "upgrade." : "tech saving."}`}
						>
							<button
								onClick={async () => {
									// Your async logic here (e.g., fetching data or performing an action)
									console.log("Button clicked");

									if (isGrant) {

										// fetch('https://hooks.zapier.com/hooks/catch/13965335/2ota6y3/', {
										// 	method: "POST",
										// 	body: JSON.stringify({
										// 		CompanyName: company?.fields?.Name,
										// 		ClientEmail: userEmail,
										// 		RequesterEmail: userEmail,
										// 		PartnerEmail: '',
										// 		Link: `${window.location.origin}/grants-form/${company.id}/${upgrade.recordId}`,
										// 		UpgradeName: upgrade?.fields?.Name,
										// 		Amount: currencyFormat(upgrade?.fields?.AnnualMargin),
										// 		ClientName: company?.fields?.Name,
										// 		Date: new Date().toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }),
										// 		UpgradeType: "Grant",
										// 		Deadline: upgrade?.fields?.Deadline,
										// 		Password: "",
										// 		SlackNotification: true,
										// 	})
										// })

										// const processedQuestions = await getQuestionsToDisplay(answers, `${upgrade?.fields?.Name}-${upgrade?.fields?.GrantUrl}`, company?.id, questions);

										// if (processedQuestions) {
										// 	// console.log("questions", questions);
										// 	// console.log("answers", answers);
										// 	// console.log('grant', upgrade);
										// 	// console.log('company', company);
										// 	// console.log('processedQuestions', processedQuestions);

										// 	// AUTODRAFT TEMP OFF
										// 	handleAutodraftGrant(upgrade, company, processedQuestions, userEmail);

										// }
									}
									if (isRequested) {
										handleRedeemClick();
									} else if (upgrade?.fields?.Technology_Upgrade) {
										handleTechClick();
									} else if (upgrade?.fields?.formula) {
										await handleReferralClick();
									} else {
										// OPEN MODAL HERE
										// After your async logic, open the modal
										openModal();

										// window.open('https://www.getupgraded.ca/free-savings-consultation', '_blank');
									}
								}}
								type="button"
								className={`box-border relative -z-5 inline-flex items-center justify-center w-56 px-2 py-3 overflow-hidden font-bold text-white bg-black transition-all duration-300 rounded-md group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none`}
							>
								<span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0" />
								<span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0" />
								<span className="relative flex items-center text-sm">
									{/* <svg
										className="relative w-4 h-4 mr-2 text-white"
										fill="none"
										stroke="currentColor"
										viewBox="0 0 24 24"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
									</svg> */}
									{/* <PhoneIcon className="h-4 w-4 text-white inline pb-1 mr-2 mt-1" aria-hidden="true" /> */}

									{/* TO DO */}
									{/* {(redeemed || isRedeemed) ? <CheckCircleIcon className="h-6 w-6 text-white mr-2" aria-hidden="true" /> : isRequested ? <CursorArrowRaysIcon className="h-6 w-6 text-yellow-400 mr-2" aria-hidden="true" /> : <StarIcon className="h-6 w-6 text-white mr-2" aria-hidden="true" />} */}

									{(redeemed || isRedeemed) ? <CheckCircleIcon className="h-6 w-6 text-yellow-400 mr-2" aria-hidden="true" /> : isRequested ? <CursorArrowRaysIcon className="h-6 w-6 text-yellow-400 mr-2" aria-hidden="true" /> : upgrade?.fields?.Technology_Upgrade ? <StarIcon className="h-6 w-6 text-yellow-400 mr-2" aria-hidden="true" /> : null}

									{loadingReferralEmails ? "Loading..." : upgrade?.fields?.formula ? "Consult An Expert" : (redeemed || isRedeemed) ? "Redeemed" : isRequested ? "Redeem Upgrade" : upgrade?.fields?.Technology_Upgrade ? "Reveal Upgrade" : "Draft For Me"}
								</span>
							</button>
						</Tooltip>
					</td>
				)}
			</tr>

			{/* Update Grant Features for Admin Mode */}
			{admin && editGrant && company && industries && (
				<tr>
					<div className="my-3">
						(The ones highlighted in red are the ones that caused the match. Separate by Commas!)
						<div className="text-sm">
							<strong>Industries : &nbsp;</strong>
							<ul>
								{upgrade?.fields?.Eligible_Industry?.split(",").map((upgradeIndustry) => (
									<li
										className={
											upgradeIndustry === "All" ||
												company?.fields?.Industry?.toLowerCase()
													.split(",")
													.filter((companyIndustry) => industries[upgradeIndustry]?.includes(companyIndustry)).length >= 1
												? "text-red-700"
												: ""
										}
									>
										• {upgradeIndustry} &nbsp;
									</li>
								))}
							</ul>
							<form
								className="text-sm"
								onSubmit={(e) => {
									e.preventDefault();
									let newIndustries = e.target["grantIndustries" + upgrade?.id].value
										?.split(",")
										.map((item) => item?.trim())
										.join(",");

									updateGrantFeature("Eligible_Industry", upgrade?.recordId, newIndustries);
								}}
							>
								<input
									id={"grantIndustries" + upgrade.id}
									className="p-1 rounded-xl text-black border-2 border-gray-500"
									placeholder=""
									defaultValue={upgrade?.fields?.Eligible_Industry?.split(",")
										.map((item) => item?.trim())
										.join(", ")}
								/>
								{/* Clear (X) Button beside input tag */}
								<button
									onClick={(e) => {
										e.preventDefault();
										document.getElementById("grantIndustries" + upgrade?.id).value = "";
									}}
								>
									<XMarkIcon className="h-6 w-auto" />
								</button>
								<button
									type="submit"
									className="mx-2 p-2 text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none"
								>
									Update Grant Industries
								</button>
							</form>
						</div>
					</div>
					<div className="my-3">
						<div className="text-sm">
							<strong>Region</strong> : &nbsp;{" "}
							{
								<p
									className={
										company?.fields?.Address?.toLowerCase()
											?.split(",")
											.map((item) => item.trim().toLowerCase())
											.includes(upgrade?.fields?.Eligible_Region?.toLowerCase()) ||
											company?.fields?.Country?.split(",")
												.map((code) => countryCodes[code])
												.some((item) => upgrade?.fields?.Eligible_Region?.toLowerCase().trim()?.split(",").includes(item))
											? "text-red-700"
											: ""
									}
								>
									{upgrade?.fields?.Eligible_Region}
								</p>
							}
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								let newRegion = e.target["grantRegion" + upgrade?.id].value
									?.split(",")
									.map((item) => item.trim())
									.join(",");

								updateGrantFeature("Eligible_Region", upgrade?.recordId, e.target["grantRegion" + upgrade?.id].value);
							}}
						>
							<input
								defaultValue={upgrade?.fields?.Eligible_Region?.split(",")
									.map((item) => item.trim())
									.join(", ")}
								id={"grantRegion" + upgrade?.id}
								className="p-1 rounded-xl border-2 border-gray-700 text-black"
							/>
							{/* Clear (X) Button beside input tag */}
							<button
								className="mt-auto"
								onClick={(e) => {
									e.preventDefault();
									document.getElementById("grantRegion" + upgrade?.id).value = "";
								}}
							>
								<XMarkIcon className="h-6 w-auto m-auto" />
							</button>
							<button
								type="submit"
								className="mx-2 p-2 text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none"
							>
								Update Grant Region
							</button>
						</form>
					</div>

					<div className="my-3">
						<div className="text-sm">
							<strong>Amount</strong> : {currencyFormat(upgrade?.fields?.AnnualMargin)}
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								let newAmount = parseFloat(e.target["grantAmount" + upgrade?.id].value.replaceAll("$", "").replaceAll(",", ""));
								if (isNaN(newAmount) || newAmount === undefined) return;
								updateGrantFeature("Amount", upgrade?.recordId, newAmount);
							}}
						>
							<input placeholder="1,000" id={"grantAmount" + upgrade?.id} className="p-1 border-2 rounded-xl border-gray-700 text-black" />
							{/* Clear (X) Button beside input tag */}
							<button
								type="submit"
								className="mt-auto"
								onClick={(e) => {
									e.preventDefault();
									document.getElementById("grantAmount" + upgrade?.id).value = "";
								}}
							>
								<XMarkIcon className="h-6 w-auto m-auto" />
							</button>
							<button
								type="submit"
								className="mx-2 p-2 text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none"
							>
								Update Grant Amount
							</button>
						</form>
					</div>

					<div className="my-3">
						<div className="text-sm">
							<strong className={checkDateFormat(upgrade?.fields?.Deadline) ? getDateColor(upgrade?.fields?.Deadline) : "text-gray-400"}>
								Deadline :{" "}
							</strong>{" "}
							{upgrade?.fields?.Deadline}
						</div>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								let newDeadline = e.target["grantDeadline" + upgrade?.id].value;
								if (!checkDateFormat(newDeadline) && newDeadline.trim().toLowerCase() !== "n/a") {
									e.target["grantDeadline" + upgrade?.id].value = "Incorrect date format";
									return;
								}

								updateGrantFeature("Deadline", upgrade?.recordId, newDeadline);
							}}
						>
							<input
								placeholder="1,000"
								defaultValue={upgrade?.fields?.Deadline}
								id={"grantDeadline" + upgrade?.id}
								className="p-1 border-2 rounded-xl border-gray-700 text-black"
							/>
							{/* Clear (X) Button beside input tag */}
							<button
								type="submit"
								className="mt-auto"
								onClick={(e) => {
									e.preventDefault();
									document.getElementById("grantDeadline" + upgrade?.id).value = "";
								}}
							>
								<XMarkIcon className="h-6 w-auto m-auto" />
							</button>
							<button
								type="submit"
								className="mx-2 p-2 text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 ease focus:outline-none"
							>
								Update Grant Deadline
							</button>
						</form>
					</div>
				</tr>
			)}

			{/* Technology Discounts Information for Admin Mode */}
			{admin && showDiscountDetails && upgrade?.fields?.Technology_Upgrade && (
				<tr>
					{isLoading && Object.keys(recommendedDiscount)?.length === 0 ? (
						<SkelUpgradeCard />
					) : (
						<td className="inline-flex">
							<ArrowRightIcon className="hidden h-6 w-6 flex-none sm:block text-xl mt-3" aria-hidden="true" />
							<div className="mx-3 my-2">
								<h2 className="text-lg font-medium leading-6">
									Recommended Saving: <br />
									{currencyFormat(recommendedDiscount?.fields?.AnnualMargin)}/yr -&nbsp;
									<a className="underline" href={recommendedDiscount?.fields?.href} target="_blank">
										{recommendedDiscount?.fields?.Name}
									</a>{" "}
									- {recommendedDiscount?.fields?.Description_2}
								</h2>
								<p className="ml-5 text-sm">{recommendedDiscount?.fields?.Description}</p>
								<br />
								<p className="ml-5 text-md">Additional Details:</p>
								<p className="ml-5 text-sm">
									{recommendedDiscount?.fields?.Details
										? recommendedDiscount?.fields?.Details.replaceAll("\\n", "\n")
										: "No additional details found."}
								</p>

								<Accordion open={openOtherSavings}>
									<AccordionHeader onClick={() => setOpenOtherSavings((prev) => !prev)}>
										<p className="text-lg font-medium leading-6">Other Discounts</p>
									</AccordionHeader>
									<AccordionBody>
										{otherDiscounts.length !== 0 ? (
											otherDiscounts.map((discount, index) => (
												<tr>
													<td>
														<button
															onClick={() => {
																setRecommendedDiscount(otherDiscounts[index]);
																setOtherDiscounts((prev) => {
																	return [recommendedDiscount, ...prev.filter((prevDis) => prevDis.id !== prev[index].id)];
																});
															}}
															className="flex mr-2 items-center max-w-sm justify-center bg-indigo-100 px-2 rounded-s-md rounded-e-md hover:bg-indigo-200"
														>
															<h2 className="flex items-center justify-center w-auto text-indigo-800">
																{/* <ChevronUpIcon className="w-4 h-auto" aria-hidden="true" />  */}
																Prioritize
															</h2>
														</button>
													</td>
													<td>
														<h4 className="text-md">
															{currencyFormat(discount?.fields?.AnnualMargin)}/yr -&nbsp;
															<a className="underline" href={discount?.fields?.href} target="_blank">
																{discount?.fields?.Name}
															</a>{" "}
															- ({discount?.fields?.Source}) - {discount?.fields?.Description_2}
														</h4>
														<p className="text-sm">{discount?.fields?.Description}</p>
														<br />
													</td>
												</tr>
											))
										) : (
											<p>No other discounts found</p>
										)}
									</AccordionBody>
								</Accordion>
							</div>
						</td>
					)}
				</tr>
			)}

			{openEditGrant && (
				<EditGrantModal
					open={openEditGrant}
					setOpen={setOpenEditGrant}
					grant={upgrade}
					setGrant={setUpgrade}
					company={company}
					industries={industries}
				/>
			)}

			{showInterestedInDialogue && (
				<GrantsInterestedInDialogue
					open={showInterestedInDialogue}
					setOpen={setShowInterestedInDialogue}
					maxInterestedGrants={maxInterestedGrants}
				/>
			)}

			{openDeleteGrantDialog && (
				<DeleteGrantDialog open={openDeleteGrantDialog} setOpen={setOpenDeleteGrantDialog} grant={upgrade} setGrants={setGrants} />
			)}

			{company && upgradeProp && openReferralDialog && (
				<SendReferralEmailDialog
					open={openReferralDialog}
					setOpen={setOpenReferralDialog}
					upgrade={upgradeProp}
					company={company}
					userEmail={userEmail}
					upgradeType={isGrant ? "grant" : isUpgrade ? "upgrade" : "tech saving"}
					setLoading={setLoadingReferralEmails}
					showReferralNotification={showReferralNotification}
					setShowReferralNotification={setShowReferralNotification}
				/>
			)}

			{company && upgradeProp && openTechRequest && (
				<TechRequest
					open={openTechRequest}
					setOpen={setOpenTechRequest}
					upgrade={upgradeProp}
					amount={currencyFormat(upgrade?.fields?.AnnualMargin) + "/yr "}
					company={company}
					setCompany={setCompany}
					userEmail={userEmail}
				/>
			)}

			{company && upgradeProp && openRedeemDialog && (
				<RedeemDialog
					open={openRedeemDialog}
					setOpen={setOpenRedeemDialog}
					upgrade={upgradeProp}
					company={company}
					setCompany={setCompany}
					userEmail={userEmail}
					redeemed={redeemed || isRedeemed}
					setRedeemed={setRedeemed}
				/>
			)}

			<Notification
				showNotif={showReferralNotification.showNotif}
				setShowNotif={(val) => setShowNotif(val, setShowReferralNotification)}
				contents={showReferralNotification.contents}
			/>

			<SendAutoDraftModal
				isOpen={isModalOpen}
				closeModal={closeModal}
				defaultEmail={company?.fields?.RequestEmail}
				company={company}
				userEmail={userEmail}
				upgrade={upgrade}
				currencyFormat={currencyFormat}
				answers={answers}
				questions={questions}
				isGrant={isGrant}
				showReferralNotification={showReferralNotification}
				setShowReferralNotification={setShowReferralNotification}
			/>
		</>
	);
}

Upgrade.defaultProps = {
	admin: false,
	fullBlur: false,
	setGrants: null,
	company: null,
	setCompany: null,
	industries: null,
	otherSavings: null,
	isGrant: false,
	isUpgrade: false,
	flagged: false,
	interested: false,
	interestedInGrants: null,
	flaggedGrants: null,
	unblur: false,
	showMissingInfoEmailToggle: false,
};

export default Upgrade;
