import {
  React,
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { FunnelIcon, TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from "@heroicons/react/24/outline";
import { currencyFormat, classNames, extractDomain } from "./helpers.js";
import { Tooltip } from "@material-tailwind/react";
import Airtable from "airtable";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Menu } from "@headlessui/react";
import { send } from "emailjs-com";
import { AppContext } from "./AppContext.js";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey }).base(baseId);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const pageSize = 36;

export default function FinancingRequests({}) {
  const [financings, setFinancings] = useState([]);

  useEffect(() => {
    base("financing")
      .select({ view: "Grid view" })
      .all()
      .then((data) => {
        setFinancings(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Financing Requests
          </h2>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            Recent financing requests we think are a good fit for you
          </p>
          <br />
        </div>

        <div className="mx-auto max-w-7xl text-center">
          <div className="grid grid-cols-3 gap-4">
            {financings.map((financing, index) => {
              const fields = financing?.fields;

              // Keys to be excluded
              const excludedKeys = [
                "firstName",
                "lastName",
                "phoneNumber",
                "legalBusinessName",
                "companyWebsite",
                "email"
              ];

              // Filter out excluded keys
              const filteredEntries = Object.entries(fields).filter(
                ([key]) => !excludedKeys.includes(key)
              );

              // Extract and format fundingAmountRequested
              const fundingAmountRequested = fields.fundingAmountRequested
                ? `$${parseInt(fields.fundingAmountRequested).toLocaleString()}`
                : null;

              return (
                <div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow-md mb-4"
                >
                  {fundingAmountRequested && (
                    <div className="flex flex-col mb-4">
                      <span className="font-medium text-lg">
                        Funding Amount Requested
                      </span>
                      <span className="text-xl text-green-600">
                        {fundingAmountRequested}
                      </span>
                    </div>
                  )}
                  {filteredEntries.map(([key, value]) => (
                    <div key={key} className="flex flex-col mb-2">
                      <span className="font-medium">{key}</span>
                      <span className="text-gray-600">{value}</span>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

FinancingRequests.defaultProps = {
  admin: false,
};
