import React, { useState } from 'react';
import { Menu } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FunnelIcon, TrashIcon, XMarkIcon, ChevronUpDownIcon, CheckIcon, ChevronDownIcon, EnvelopeIcon, ChatBubbleOvalLeftIcon, PhoneArrowUpRightIcon, LinkIcon, CloudArrowUpIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import Tooltip from './Tooltip';


const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const projects = [
    {
        id: 0,
        name: 'Sincere & Well researched',
        href: '#',
        status: 'Complete',
        createdBy: 'Hey Shimona, hope youre doing well. I looking into {Company} and ran a savings report for you and based on your technologies believe I could save you about $XX,XXX/yr. It only costs $1 to sign up and 20% of money awarded or found. Open to trying it out or a quick call?',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 1,
        name: 'Low Key Friend',
        href: '#',
        status: 'Complete',
        createdBy: 'Opposed to me finding you free money on contingency?',
        dueDateTime: '2023-03-17T00:00Z',
    },
    {
        id: 2,
        name: 'Showing Value',
        href: '#',
        status: 'In progress',
        createdBy: 'I made this savings report for you app.getupgraded.ca/showing-value, it has over $100,000/yr inside.',
        dueDate: 'May 5, 2023',
        dueDateTime: '2023-05-05T00:00Z',
    },
    {
        id: 3,
        name: 'Everyone else is doing it',
        href: '#',
        status: '#',
        createdBy: 'Theyve saved themselves $112,500/yr and helped SubcoDevs, RiskThinking, and DigitalLink save an average of $104,321/yr',
        dueDate: 'May 25, 2023',
        dueDateTime: '2023-05-25T00:00Z',
    },
    {
        id: 4,
        name: '$1 to start, Contingency',
        href: '#',
        status: 'In progress',
        createdBy: "Hi person.fields.FirstName, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=${person.fields.Website}",
        dueDate: 'June 7, 2023',
        dueDateTime: '2023-06-07T00:00Z',
    },
    {
        id: 5,
        name: 'Compliment',
        href: '#',
        status: 'Archived',
        createdBy: 'I love the way you handle yourself, I just came across this new thing called Upgraded. They saved me $57,000',
        dueDate: 'June 10, 2023',
        dueDateTime: '2023-06-10T00:00Z',
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ClipboardModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const handleCopy = (iceBreaker) => {
        // console.log(person, "person")
        console.log(iceBreaker, "iceBreaker")


        // const textToCopy = `Hi ${person.fields.FirstName}, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=${person.fields.Website}`;
        // const textToCopy = `Hi there, I'd like to share a way to find money you didn't know about before, it's called Upgraded. They work entirely on a contingency basis. They've done over $9.4m in grants, vendor savings and automations at an ~84% success rate and your report shows you have over $105,231/yr in savings or $8,769/mo. Kindly see this link to your report below, would love https://app.getupgraded.ca/?website=`;
        const textToCopy = iceBreaker.createdBy;
        navigator.clipboard.writeText(textToCopy)
        // .then(
        //     () => {
        //         setCopySuccess('Copied to clipboard!');
        //     },
        //     (err) => {
        //         setCopySuccess('Failed to copy text.');
        //     }
        // );
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
                <h2 className="text-2xl font-bold mb-4">Clever Phrases To Break The Ice</h2>
                <ol className="list-decimal list-inside space-y-2">
                    <ul role="list" className="divide-y divide-gray-100">
                        {projects.map((project) => (
                            <li key={project.id} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{project.name}</p>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        {project.createdBy}
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                    <Tooltip text="Copied!">
                                        <button
                                            // href={project.href}
                                            onClick={() => handleCopy(projects[project.id])}
                                            className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block cursor-pointer"
                                        >
                                            <ClipboardDocumentListIcon
                                                className="h-5 w-5"
                                            />
                                        </button>
                                    </Tooltip>


                                    {/* <Menu as="div" className="relative flex-none">
                                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                            <span className="sr-only">Open options</span>
                                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                        <Menu.Items
                                            transition
                                            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                        >
                                            <Menu.Item>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                        )}
                                                    >
                                                        Edit<span className="sr-only">, {project.name}</span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                        )}
                                                    >
                                                        Move<span className="sr-only">, {project.name}</span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            focus ? 'bg-gray-50' : '',
                                                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                                                        )}
                                                    >
                                                        Delete<span className="sr-only">, {project.name}</span>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Menu> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                </ol>
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ClipboardModal;
