import React, { useState, useEffect, useContext } from 'react';
import GrantAnswersCard from './GrantAnswersCard';
import Cookies from 'js-cookie';
import OpenAI from 'openai';
import Skeleton from 'react-loading-skeleton';
import { encodingForModel } from "js-tiktoken";
import Airtable from "airtable";
import {
  isNumbered,
  numberingCompare,
  revise,
  isAnswer,
  hasSampleAnswer,
  setContents, 
  setShowNotif,
  removeQueryParameters
} from './helpers.js';
import Notification from "./Notification";
import { CheckIcon } from '@heroicons/react/20/solid';
import { InfinitySpin } from 'react-loader-spinner';
import { useUser } from '@clerk/clerk-react';
import { AppContext } from './AppContext.js';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey: apiKey }).base(baseId);

function hasLessThanNWords(text, wordLimit) {
  if (!text) {
    return false;
  }
  // Split the text by spaces to count the words
  const words = text.trim().split(/\s+/);

  // Check if the number of words is less than the specified limit
  return words.length < wordLimit;
}

export default function GrantAnswersForm({
  loading,
  grant,
  grants,
  company,
  questions,
  setQuestions,
  answers,
  setAnswers,
  admin,
}) {
  // notification states:
  const [autodraftNotif, setAutodraftNotif] = useState({
		showNotif: false,
		contents: { type: "", heading: "", message: "" },
	});

	const { isSignedIn, isLoaded, user } = useUser();
	const { clerkUser, setClerkUser } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [pastAnswersContext, setPastAnswersContext] = useState([]);
  const [questionsToDisplay, setQuestionsToDisplay] = useState([null]);
  // const [questionsByCompany, setQuestionsByCompany] = useState([]);
  const [grantAutodraftLoading, setGrantAutodraftLoading] = useState(false);
  const [sampleAnswerShow, setSampleAnswerShow] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPastAnswers, setShowPastAnswers] = useState(false);
	const [showInfo, setShowInfo] = useState(false);
  const [prompt1, setPrompt1] = useState(
    `You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company. Do not make up answer for questions you do not have enough context for. If a question needs more context, write "NEED MORE CONTEXT" and write what we know so far\r\n`
  );
  const [prompt2, setPrompt2] = useState(
    `Name: ${company.fields.Name}. \r\n Employees: ${company.fields.Employees}. \r\n Country: ${company.fields.Country}. \r\n Province: ${company.fields.ProvinceOrState}. \r\n Industry: ${company.fields.Industry}. \r\n Founded: ${company.fields.Founded}. Profitable: ${company.fields['Profitable?']}\r\n`
  );
  const [prompt3, setPrompt3] = useState(
    `This is the description of the company named ${company.fields.Name}. \r\n Description: ${company.fields.Description} \r\n`
  );
  const [prompt4, setPrompt4] = useState(
    `The following is additional information about the company.\r\n ${company.fields.LongDescription}`
  );
  const [useEditedPrompt, setUseEditedPrompt] = useState(false);

	// initialization of states
	useEffect(() => {
		// exit useEffect if states aren't ready
		if (!isLoaded || !user || !isSignedIn) return;

		// set clerkUser and userRecord states
    setClerkUser(user);
	
	}, [isLoaded, user, isSignedIn]);

  useEffect(() => {
    let questionAndAnswers = [];

    const answersByCompany = answers
      .filter((answer) => answer.fields.CompanyID === company.id)
      .filter((answer) => hasLessThanNWords(answer.fields.AnswerText, 50));

    answersByCompany.forEach(answer => {
        // Find the corresponding question based on QuestionID
        const matchingQuestion = questions.find(question => question.fields.QuestionID === answer.fields.QuestionID);
        
        if (matchingQuestion) {
            questionAndAnswers.push({
                question: matchingQuestion.fields.QuestionText,
                answer: answer.fields.AnswerText
            });
        }
    });

    // console.log("CONTEXT:", JSON.stringify(questionAndAnswers));
    setPastAnswersContext(questionAndAnswers);

}, [answers, company, questions]);

useEffect(() => {
  // Filter the past answers based on the search query
  const results = pastAnswersContext.filter(item =>
      item?.question?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  setFilteredAnswers(results);
}, [searchQuery, pastAnswersContext]);

const handleInputChange = (index, field, value) => {
  const newData = [...pastAnswersContext];
  newData[index][field] = value;
  setPastAnswersContext(newData);
};


  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_APIKEY,
    dangerouslyAllowBrowser: true,
  });

  const getQuestionsByCompany = (answersArray, grantsArray, companyRecord) => {
    const interestedInGrants = grantsArray?.filter((grantRecord) =>
      companyRecord.fields?.InterestedIn?.split(',')?.includes(
        grantRecord.fields?.Name
      )
    );
    if (!interestedInGrants || interestedInGrants.length === 0) return [];

    let questionsByCompany = [];
    for (const grantRecord of interestedInGrants) {
      const grantQuestions = getQuestionsByGrant(grantRecord.id)
        .map((question) => {
          let newQuestion = question;
          newQuestion['answer'] = getAnswer(
            answersArray,
            companyRecord.id,
            question.fields.QuestionID,
            grantRecord.id
          );
          return newQuestion;
        })
        .filter((question) => Boolean(question.answer));

      questionsByCompany = [...questionsByCompany, ...grantQuestions];

      // NOTE : questionsByCompany COULD have duplicates, BUT with different answers, which means we still want the 'duplicates'
    }

    return questionsByCompany;
  };

  // refactored to grantIdentifier since we don't use ids anymore
  const getQuestionsByGrant = (grantIdentifier) => {
    if (loading) return [];
    return questions.filter((question) => {
      if (!question?.fields?.grantIdentifier) return false;
      else return question?.fields?.grantIdentifier?.split(',').includes(grantIdentifier);
    });
  };

  const getAnswer = (answersArray, companyId, questionId, grantIdentifier) => {
    let possibleAnswers = answersArray.filter(
      (ans) =>
        ans?.fields?.AnswerText &&
        ans.fields?.CompanyID === companyId &&
        ans.fields.QuestionID === questionId &&
        ans.fields.grantIdentifier === grantIdentifier
    );
    if (possibleAnswers.length === 0) return null;
    else if (possibleAnswers.length === 1) {
      // if (!possibleAnswers[0]?.fields?.AnswerText || possibleAnswers[0].fields.AnswerText === "") return null;

      return possibleAnswers[0];
    } else {
      console.log(
        'There are multiple possible answers for question ' +
          questionId +
          ' from Company ' +
          companyId +
          '.'
      );
      console.log(possibleAnswers);
      if (
        !possibleAnswers[0]?.fields?.AnswerText ||
        possibleAnswers[0].fields.AnswerText === ''
      )
        return null;
      return possibleAnswers[0];
    }
  };

  // NOTE : Requires that each question in questionsArray has an 'answer' property which contains the answer object.
  //		  If no answer, then the answer property is null.
  const getQuestionsAnswersSorted = (questionsArray) => {
    return questionsArray.sort((a, b) => {
      if (a.fields.Link && b.fields.Link);
      else if (a.fields.Link) return -1;
      else if (b.fields.Link) return 1;

      // Questions that have answers that need revision are at the top.
      if (revise(a.answer) && revise(b.answer));
      else if (revise(a.answer)) return -1;
      else if (revise(b.answer)) return 1;

      // Answers to Questions that are not numbered should always appear at the bottom
      if (!isNumbered(a?.fields?.QuestionText) && isAnswer(a.answer)) return 1;
      else if (!isNumbered(b?.fields?.QuestionText) && isAnswer(b.answer))
        return -1;

      // Numbered Questions appear after un-numbered questions
      if (
        isNumbered(a?.fields?.QuestionText) &&
        isNumbered(b?.fields?.QuestionText)
      )
        return numberingCompare(a.fields.QuestionText, b.fields.QuestionText);
      else if (isNumbered(a?.fields?.QuestionText)) return 1;
      else if (isNumbered(b?.fields?.QuestionText)) return -1;

      // Answered Questions appear after un-answered questions
      if (!isAnswer(a.answer) && !isAnswer(b.answer)) return 0;
      else if (!isAnswer(a.answer)) return -1;
      else if (!isAnswer(b.answer)) return 1;

      return 0;
    });
  };

  const getQuestionsToDisplay = (answersArray, grantIdentifier, companyId) => {
    return getQuestionsAnswersSorted(
      getQuestionsByGrant(grantIdentifier).map((question) => {
        let newQuestion = question;
        newQuestion['answer'] = getAnswer(
          answersArray,
          companyId,
          question.fields.QuestionID,
          grantIdentifier
        );
        return newQuestion;
      })
    );
  };

  // Requires that the companyRecord represents a valid Company with the following fields being not undefined:
  // 		Name, Employees, Country, ProvinceOrState, Industry, Founded, Profitable?, Description, LongDescription
  const getInitialAutodraftMessages = (companyRecord) => {
		let prompts = [
			`You will be given information about a company and then be asked to answer a question regarding the company. Answer the question from the perspective of the company.\r\n`, 
			
			`Name: ${companyRecord.fields.Name}. \r\n Employees: ${companyRecord.fields.Employees}. \r\n Country: ${companyRecord.fields.Country}. \r\n Province: ${companyRecord.fields.ProvinceOrState}. \r\n Industry: ${companyRecord.fields.Industry}. \r\n Founded: ${companyRecord.fields.Founded}. Profitable: ${companyRecord.fields['Profitable?']}\r\n`,
		
			`This is the description of the company named ${companyRecord.fields.Name}. \r\n Description: ${companyRecord.fields.Description} \r\n`,
		
			`The following is additional information about the company.\r\n ${companyRecord.fields.LongDescription}`,
		];
		if (useEditedPrompt) {
			prompts = [prompt1, prompt2, prompt3, prompt4];
		}
    return [
      {
        role: 'system',
        content: prompts[0],
      },
      {
        role: 'user',
        content: prompts[1],
      },
      {
        role: 'user',
        content: prompts[2],
      },
      {
        role: 'user',
        content: prompts[3],
      },
    ];
  };

	const submitAnswer = (companyId, questionId, grantIdentifier, answer, newAnswerText) => {
		const answersTableId = "tblaUCzGrjHIiKgQ3";

		if (isAnswer(answer) || (answer?.fields && answer.id)) {
			// Send PATCH request to edit answer
			// patch record answer.id to have new info newAnswerText

			fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId + "/" + answer.id, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},

				body: JSON.stringify({
					fields: {
						AnswerText: newAnswerText,
					},
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error(JSON.stringify(response));
					}

					return response.json();
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						JSON.stringify(data).toLowerCase().includes("error") ? reject(data) : resolve(data);
					});
				})
				.then(() => {
					base("answers")
						.select({ view: "Grid view" })
						.all()
						.then((records) => {
							setAnswers(records);
						});

				})
				.catch((error) => {
					console.error("Error in editing answer:", error);
				});
		} else {
			// Send POST request to create answer
			fetch("https://api.airtable.com/v0/" + baseId + "/" + answersTableId, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${apiKey}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					fields: {
						AnswerText: newAnswerText,
						CompanyID: companyId,
						grantIdentifier: grantIdentifier,
						QuestionID: questionId,
						AnswerID: `${Date.now()}`,
					},
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error(JSON.stringify(response));
					}

					return response.json();
				})
				.then((data) => {
					return new Promise((resolve, reject) => {
						JSON.stringify(data).toLowerCase().includes("error") ? reject(data) : resolve(data);
					});
				})
				.then(() => {
					base("answers")
						.select({ view: "Grid view" })
						.all()
						.then((records) => {
							setAnswers(records);
						});

				})
				.catch((error) => {
					console.error("Error in submitting Answer:", error);
				});
		}
	};

  const autodraftAnswer = (companyRecord, question) => {
    if (!question) {
      console.error('Invalid question');
      return;
    }

    const questionText = question.fields.QuestionText;

    const maxInputTokens = 16385 - 1000; // Change to match the model, -1000 to account for innaccuracies in tiktoken
    const model = 'gpt-4o';

    const questionMessages = pastAnswersContext?.length > 0 ? [
			{ role: "user", content: `The following is a series of questions of the company and the company's answers:\r\n` },
			...pastAnswersContext.map((q) => ({
				role: "user",
				content: `Question: ${q.question}. \r\n Answer: ${q.answer}`,
			})),
		] : [];


    const initialMessages = getInitialAutodraftMessages(companyRecord);

    let questionToAskMessage = {
      role: 'user',
      content: `Based on what you know about ${companyRecord.fields.Name}, answer this question: ${questionText}.`,
    };

    if (hasSampleAnswer(question)) {
      questionToAskMessage.content += `\n Please format your answer according to this sample answer: ${question.fields.SampleAnswer}`;
    }

    let messages = [...initialMessages, ...questionMessages, questionToAskMessage];

    // remove enough context to not exceed maxInputTokens
    try {
			const encoder = encodingForModel(model);
			let tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
			let numberOfQuestionsRemoved = 0;
			if (tokenCount > maxInputTokens) {
				
				while (tokenCount > maxInputTokens) {
					if (questionMessages.length === 0) {
						throw new Error(`Number of tokens required ${tokenCount} is larger than maximum input tokens ${maxInputTokens}`);
					}

					questionMessages.splice(0, 1);
					++numberOfQuestionsRemoved;

					messages = [...initialMessages, ...questionMessages, questionToAskMessage];
					tokenCount = encoder.encode(messages.map((message) => message.content).join("\n")).length;
				}
			}

			console.log("context messages", questionMessages);
			console.log("we removed", numberOfQuestionsRemoved, "questions");
		} catch (e) {
			console.error("Error:", e);
		}

    try {
      const chatCompletion = openai.chat.completions.create({
        model: model,
        messages: messages,
      });

      return chatCompletion;
    } catch (e) {
      console.error(e);
      return 'N/A';
    }
  };

  const getAutodraftQuestionsText = (companyRecord, questions) => {
    return Promise.all(
      questions.map((q) => {
        if (isAnswer(q?.answer)) {
          const qText = `<strong>${q.fields.QuestionText}</strong>\nAnswer: ${q.answer.fields?.AnswerText}`;
          return qText;
        } else {
					const answer = q?.answer;
          const element = document.getElementById(q.fields.QuestionID);

          return new Promise((resolve, reject) => {
            autodraftAnswer(companyRecord, q)
              .then((data) => {
                const qAnswer = data.choices[0].message.content;
                element.value = qAnswer;
								submitAnswer(company.id, q.fields.QuestionID, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, answer, qAnswer);
                resolve(
                  `<strong>${q.fields.QuestionText}</strong>\nAnswer: <p style="color: red; display: inline-flex;">[ AutoDrafted ]<p> ${qAnswer}`
                );
              })
              .catch((error) => {
                console.error(
                  `Error in autodrafting answer for question ${q.fields.QuestionText}, ${q.fields.QuestionID}`,
                  error
                );
                reject(error);
              });
          });
        }
      })
    );
  };

  // Handles the onClick event for the Grant Autodraft button.
  // Note : questions is an array of mutated question objects -- each question object must have an 'answer' property, representing
  // 		  an answer to that question from this company for this grant. This property can be null to represent no answer from this
  //		  company for this grant.
  const handleAutodraftGrant = async (
    grantRecord,
    companyRecord,
    questions
  ) => {
    try {
      // TODO : Add this env variable so it works on local
      const webhookURL = `https://hooks.zapier.com/hooks/catch/16093819/${process.env.REACT_APP_ZAPIER_AUTODRAFT}/`;

      setGrantAutodraftLoading(true);

      const questionsText = (
        await getAutodraftQuestionsText(companyRecord, questions)
      ).join('\n\n');

      setContents(
        { type: "success", heading: "Autodraft Successful", message: "Document should be ready in Google Drive. Check Slack for the link" },
        setAutodraftNotif
      );
      setShowNotif(true, setAutodraftNotif);

      fetch(webhookURL, {
        method: 'POST',
        body: JSON.stringify({
          grant: grantRecord.fields.Name,
          company: companyRecord.fields.Name,
          questions: questionsText,
          amount: grantRecord.fields.AnnualMargin,
          clientEmail: clerkUser.primaryEmailAddress.emailAddress,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(JSON.stringify(response));
          }

          return response.json();
        })
        .then((data) => console.log('data', data))
        .catch((e) => console.error('Error in sending autodraft request: ', e))
        .finally(() => setGrantAutodraftLoading(false));
    } catch (err) {
      setContents(
        { type: "error", heading: "Autodraft Unsuccessful", message: `${err.message}` },
        setAutodraftNotif
      );
      setShowNotif(true, setAutodraftNotif);
    }
  };

  function updateCompany(fieldName, companyId, newValue) {
    console.log('updateCompany', fieldName, companyId, newValue);
    const field = fieldName;
    fetch(
      'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        companyId,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            [fieldName]: newValue,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error('Error:', error));
    // setCompany(company);
  }

  // useEffect to compute questionsByCompany state
  // useEffect(() => {
  //   if (!questions || questions.length === 0) return;
  //   if (!answers || answers.length === 0) return;
  //   if (!grants || grants.length === 0) return;
  //   if (!company?.fields?.InterestedIn) return;

  //   setQuestionsByCompany(getQuestionsByCompany(answers, grants, company));
  // }, [questions, answers, grants, company]);

  // useEffect to compute questionsToDisplay state
  useEffect(() => {
    if (!questions || questions.length === 0) return;
    if (!answers || answers.length === 0) return;
    if (!grant?.id) return;
    if (!company?.id) return;

    setQuestionsToDisplay(getQuestionsToDisplay(answers, `${grant?.fields?.Name}-${removeQueryParameters(grant?.fields?.GrantUrl)}`, company.id));
  }, [questions, answers, grant, company]);

  return loading ? (
    <div className="py-4 items-center">
      {[1, 2, 3].map((item) => (
        <div
          key={item}
          className="mt-3 flex items-center justify-center w-full max-w-screen"
        >
          <div className="mt-3 my-1 items-left justify-left border-t border-gray-100">
            <Skeleton height={50} width={500} />
          </div>
        </div>
      ))}
    </div>
  ) : questionsToDisplay.length === 0 ? (
    <div className="flex justify-center items-center text-center mt-3">
      <div className="relative max-w-2xl justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
        <CheckIcon
          className="mx-auto h-12 w-12 text-gray-400 hover:text-green-400"
          aria-hidden
        />
        <span className="mt-2 block text-md text-gray-900">
          We don't have any questions for you at the moment
        </span>
        <span className="mt-2 block text-sm text-gray-900">
          Currently working on getting your team{' '}
          {grant?.fields?.Name ? grant.fields.Name : 'this funding opportunity'}
        </span>
      </div>
    </div>
  ) : (
    <div>
      <div className="mt-2 ml-5 p-3 md:flex md:items-center md:justify-center text-center">
        <div className="min-w-0 flex-1">
          <h1 className="text-md leading-7 text-gray-900">
            We need some more information to continue working on your{' '}
            <b>{grant.fields.Name}</b> Application.
          </h1>
          {admin && (
            <div className="m-2 p-2 flex justify-end items-end">
              <button
                onClick={async () =>
                  await handleAutodraftGrant(grant, company, questionsToDisplay)
                }
                className="rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
                disabled={grantAutodraftLoading}
              >
                {grantAutodraftLoading ? 'Loading...' : 'Autodraft Grant'}
              </button>
              <button
                onClick={async () =>
                  showPrompt ? setShowPrompt(false) : setShowPrompt(true)
                }
                className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
                disabled={grantAutodraftLoading}
              >
                {showPrompt ? 'Hide' : 'Show'} Prompt
              </button>
              <button
                onClick={async () =>
                  showPastAnswers ? setShowPastAnswers(false) : setShowPastAnswers(true)
                }
                className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
                disabled={grantAutodraftLoading}
              >
                {showPastAnswers ? 'Hide' : 'Edit'} Context Given To AI
              </button>
							<button
                onClick={() => setShowInfo(prev => !prev)}
                className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
                disabled={grantAutodraftLoading}
              >
                {showInfo ? 'Hide' : 'Edit'} Company Scrapped Info
              </button>
              <button
                onClick={async () =>
                  sampleAnswerShow
                    ? setSampleAnswerShow(false)
                    : setSampleAnswerShow(true)
                }
                className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
                disabled={grantAutodraftLoading}
              >
                {sampleAnswerShow ? 'Hide' : 'Show'} Sample Answers
              </button>
              <button
                onClick={() => window.open('/edit-grant-questions', '_blank')}
                className="ml-2 rounded-md bg-white px-2.5 py-1.5 text-sm text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                type="button"
              >
                Edit Grant
              </button>
            </div>
          )}
					{admin && <p className='font-bold text-right'>Currently using{' '} {useEditedPrompt ? 'edited prompt' : 'default prompt'}</p>}
        </div>
      </div>
      {admin && showInfo && (
        <div className='text-center mx-auto'>
          <h3 className="font-bold">Company Info</h3>
          {/* {company.fields.LongDescription} */}
          <div className="my-3">
            <div className="text-sm">
              <strong>Company Info</strong> : &nbsp; <br />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                let newValue = e.target['longDescription' + company?.id].value;

                updateCompany('LongDescription', company?.id, newValue);
              }}
            >
              <div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3">
                <div className="sm:row-start-1">
                  <textarea
                    rows="12"
                    cols="100"
                    defaultValue={company?.fields?.LongDescription}
                    type="text"
                    id={'longDescription' + company?.id}
                    className="p-1 rounded-xl border-2 border-gray-700 text-black"
                  />
                </div>
                <div className="inline-flex mx-auto">
                  <button
                    type="submit"
                    className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
                  >
                    Update Company Scrapped Info
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* {JSON.stringify(pastAnswersContext)} */}

      {admin && showPastAnswers && (
          <div className="space-y-4">
              <h2 className="font-bold text-xl text-center">Past Answers</h2>
              <hr />

              {/* Search Bar */}
              <div className="flex justify-center">
                  <input
                      type="text"
                      placeholder="Search questions..."
                      className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent w-full max-w-md"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                  />
              </div>

              {/* Filtered Past Answers */}
              {filteredAnswers.map((item, index) => (
                  <div key={index} className="flex flex-col space-y-2 px-20">
                      <label className="text-sm font-medium text-gray-700">
                          {item.question}
                      </label>
                      <textarea
                          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          value={item.answer}
                          onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
                          rows="3"
                      />
                  </div>
              ))}
          </div>
      )}




      {admin && showPrompt && (
        <div className="text-center mx-auto my-16">
          <div className="mx-auto">
            <div className="my-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setUseEditedPrompt((prev) => !prev);
                }}
              >
                <h2>Autodraft Prompt:</h2>
                <hr></hr>
                <div className="sm:grid sm:grid-flow-row-dense sm:grid-row-2 sm:gap-3 mt-10">
                  <div className="sm:row-start-1">
                    <label>System Instructions</label>
                    <div>
                      <textarea
                        rows="8"
                        cols="100"
                        value={prompt1}
                        onChange={(e) => setPrompt1(e.target.value)}
                        type="text"
                        className="p-1 rounded-xl border-2 border-gray-700 text-black"
                      />
                    </div>
                    <label>Company Details</label>
                    <div>
                      <textarea
                        rows="8"
                        cols="100"
                        value={prompt2}
                        onChange={(e) => setPrompt2(e.target.value)}
                        type="text"
                        className="p-1 rounded-xl border-2 border-gray-700 text-black"
                      />
                    </div>
                    <label>Company Description</label>
                    <div>
                      <textarea
                        rows="8"
                        cols="100"
                        value={prompt3}
                        onChange={(e) => setPrompt3(e.target.value)}
                        type="text"
                        className="p-1 rounded-xl border-2 border-gray-700 text-black"
                      />
                    </div>
                    <label>
                      Company Long Description (scrapped, also known as company
                      info)
                    </label>
                    <div>
                      <textarea
                        rows="8"
                        cols="100"
                        value={prompt4}
                        onChange={(e) => setPrompt4(e.target.value)}
                        type="text"
                        className="p-1 rounded-xl border-2 border-gray-700 text-black"
                      />
                    </div>
                  </div>
                  <div className="inline-flex mx-auto">
                    <button
                      type="submit"
                      className="mx-2 p-2 max-w-md w-full text-sm text-center box-border relative -z-5 inline-flex items-center justify-center w-auto  overflow-hidden font-bold text-white transition-all duration-300 bg-gray-900 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-gray-300 ring-offset-gray-200 hover:ring-offset-gray-500 hover:bg-gray-800 focus:border-indigo-700 ease"
                    >
                      {useEditedPrompt
                        ? 'Use Default Prompt'
                        : 'Use Edited Prompt'}
                    </button>
                  </div>
                  <p>
                    Currently using{' '}
                    {useEditedPrompt ? 'edited prompt' : 'default prompt'}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="mt-3 mx-4 items-center justify-center w-full max-w-screen">
        <div className="items-center justify-center">
          <ul role="list" className="divide-y divide-gray-200">
            {questionsToDisplay.map((question) => {
              if (!Boolean(question)) return;

              const answer = question['answer'];

              return (
                <div
                  className="max-w-screen w-full"
                  key={question.fields.QuestionID}
                >
                  <GrantAnswersCard
                    grant={grant}
                    answer={answer}
                    setAnswers={setAnswers}
                    company={company}
                    question={question}
                    // companyQuestions={questionsByCompany}
                    admin={admin}
                    sampleAnswerShow={sampleAnswerShow}
                    pastAnswersContext={pastAnswersContext}
                  />
                </div>
              );
            })}
          </ul>
        </div>
      </div>
      <Notification
				showNotif={autodraftNotif.showNotif}
				setShowNotif={(val) => setAutodraftNotif(val, setAutodraftNotif)}
				contents={autodraftNotif.contents}
			/>
    </div>
  );
}
