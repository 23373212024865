import React, { useState, useEffect, useContext } from "react";
import Airtable from "airtable";
import { InfinitySpin } from "react-loader-spinner";
import NotFoundPage from "./NotFoundPage.jsx";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import GrantAnswersForm from "./GrantAnswersForm.js";
import { useUser } from "@clerk/clerk-react";
import { extractDomain } from "./helpers.js";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext.js";

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const base = new Airtable({ apiKey: apiKey }).base(baseId);
const urlSearchValue = window.location.pathname
	?.split("/")
	?.filter((item) => item)
	?.slice(1);

export default function GrantAnswersPage({}) {
	const [companies, setCompanies] = useState([]);
	const [company, setCompany] = useState({});
	const [grants, setGrants] = useState([]);
	const [grant, setGrant] = useState({});
	const { questions, setQuestions, answers, setAnswers } = useContext(AppContext);
	const [notFound, setNotFound] = useState(false);
	const [loading, setLoading] = useState(true);
	const [admin, setAdmin] = useState(false);

	// Auth - User Verification
	const navigate = useNavigate();
	const { isLoaded: isUsersLoaded, isSignedIn, user } = useUser();
	useEffect(() => {
		if (!isSignedIn || !isUsersLoaded) return;

		const userDomain = extractDomain(user.primaryEmailAddress.emailAddress);
		const isAdmin = userDomain === process.env.REACT_APP_COOL_EMAIL;
		setAdmin(isAdmin);

		const urlDomain = urlSearchValue[0];
		if (urlDomain !== userDomain && !isAdmin) {
			navigate("/not-found");
		}
	}, [user, isSignedIn, isUsersLoaded]);

	const validURLSearchValue = (searchValue) => {
		return searchValue && typeof searchValue === "object" && searchValue.length > 0 && searchValue.length <= 2;
	};

	// fetching grants
	useEffect(() => {
		if (!validURLSearchValue(urlSearchValue)) {
			console.log("invalid urlSearchValue");
			setNotFound(true);
			return;
		}

		if (urlSearchValue.length === 1) {
			console.log("urlSearchValue contains only the Domain");
			return;
		}

		let grantId = urlSearchValue[1];

		base("deduped_grants")
			.select({ 
				view: "Grid view",
				filterByFormula: `RECORD_ID() = '${grantId}'`,
			})
			.all()
			.then((records) => {
				if (records.length === 0) {
					setNotFound(true);
					return;
				}

				setGrant(records[0]);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	// fetching companies
	useEffect(() => {
		if (!urlSearchValue) {
			console.log("urlSearchValue is undefined");
			setNotFound(true);
			setLoading(false);
			return;
		}

		let companyId = urlSearchValue[0];

		base("companies")
			.select({ 
				view: "Grid view",
				filterByFormula: `RECORD_ID() = '${companyId}'`,
			})
			.all()
			.then((records) => {
				if (records.length === 0) {
					setNotFound(true);
					return;
				}

				setCompany(records[0]);
				

				//																  //
				// CODE TO RESTRICT THE GRANT ANSWERS PAGE TO ONLY COMPANY EMAILS //
				//																  //

				// if (extractDomain(Cookies.get("email")) != companyDomain && Cookies.get("domain") != "all") {
				// 	console.log(`Email ${Cookies.get("email")} is not authorized to view this page`);
				// 	setNotFound(true);
				// 	setLoading(false);
				// 	return;
				// }
			});
	}, []);

	// Switching Loading from True to False
	useEffect(() => {
		if (loading === false) return;

		if (Object.keys(grant).length != 0 && Object.keys(company).length != 0 && questions.length != 0 && answers.length != 0) {
			if (!company?.fields?.InterestedIn || !grant?.fields?.Name) setNotFound(true);
			else if (company.fields.InterestedIn.split(",").includes(grant.fields.Name) === false) {
				setNotFound(true);
				console.log("Company is not interested in this grant.");
			}

			setLoading(false);
		}
	}, [grant, company, questions, answers]);

	return (
		<>
			{loading ? (
				<div className="flex h-screen m-auto max-w-screen w-full justify-center items-center">
					<InfinitySpin color="blue" width="200"/>
				</div>
			) : notFound ? (
				<NotFoundPage />
			) : (
				<>
					<div className="m-4">
						<div className="p-2">
							<nav className="sm:hidden" aria-label="Back">
								<a
									href={`/?website=${company.fields.Domain}&company=${company.fields.Name}`}
									className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
								>
									<ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
									{company.fields.Name}'s Report
								</a>
							</nav>
							<nav className="hidden sm:flex" aria-label="Breadcrumb">
								<ol role="list" className="flex items-center space-x-4">
									<li>
										<div className="flex items-center">
											<span className="inline-flex">
												<button
													className="inline-flex hover:text-gray-700 hover:fill-text-gray-700"
													onClick={() =>
														(window.location.href = `/?website=${company.fields.Domain}&company=${company.fields.Name}`)
													}
												>
													<ChevronLeftIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
													<p aria-current="page" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
														{company.fields.Name}'s Report
													</p>
												</button>
											</span>
										</div>
									</li>
								</ol>
							</nav>
						</div>
						<div className="mt-2  md:items-center md:justify-between md:text-center">
							<div className="min-w-0 flex-1">
								<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
									{company?.fields?.Name && `Welcome, Team ${company.fields.Name}`}
								</h2>
							</div>
						</div>
					</div>

					<div>
						<GrantAnswersForm
							loading={loading}
							company={company}
							grant={grant}
							grants={grants}
							questions={questions}
							setQuestions={setQuestions}
							answers={answers}
							setAnswers={setAnswers}
							admin={admin}
						/>
					</div>
				</>
			)}
		</>
	);
}
