import {
  React,
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { FunnelIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/outline';
import { currencyFormat, classNames, extractDomain } from './helpers.js';
import { Tooltip } from '@material-tailwind/react';
import Airtable from 'airtable';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Menu } from '@headlessui/react';
import { send } from 'emailjs-com';
import { AppContext } from './AppContext.js';

const apiKey = process.env.REACT_APP_AIR_KEY;
const baseId = process.env.REACT_APP_AIR_BASEID;
const companiesTableId = process.env.REACT_APP_AIR_COMPANIES;

const base = new Airtable({ apiKey }).base(baseId);

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? 'rotate-180' : ''
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const pageSize = 36;

export default function Companies({}) {
  const [admin, setAdmin] = useState(false);
  const {
    companies,
    setCompanies,
    offset,
    setOffset,
    showAmount,
    setShowAmount,
    clerkUser,
    setClerkUser,
  } = useContext(AppContext);
  const [companySearch, setCompanySearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState({});
  const [industries, setIndustries] = useState({});
  const [shouldSort, setShouldSort] = useState(false);
  const [shouldLoad, setShouldLoad] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    Industries: [],
  });
  const [isOpen, setIsOpen] = useState(false);

  const displayResults =
    searchResults.length > 0 ? searchResults : companies.slice(0, showAmount);

  useEffect(() => {
    if (companies.length > 0 || !clerkUser) return;
    if (
      extractDomain(clerkUser.primaryEmailAddress.emailAddress) ===
      process.env.REACT_APP_COOL_EMAIL
    )
      setAdmin(true);
    loadCompanies();
  }, [clerkUser]);

  const loadCompanies = async () => {
    try {
      let params = {
        pageSize,
        view: 'Grid view',
        offset,
      };

      if (shouldSort) {
        params.sort = [{ field: 'createdTime', direction: 'desc' }];
      }

      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/companies`,
        {
          headers: { Authorization: `Bearer ${apiKey}` },
          params,
        }
      );
      console.log('Response:', response);
      setCompanies((prevCompanies) => [
        ...prevCompanies,
        ...response.data.records,
      ]);
      setShowAmount((prevAmount) => prevAmount + response.data.records.length);
      setOffset(response.data.offset);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleShowMore = () => {
    loadCompanies();
  };

  const handleSort = () => {
    setShouldSort(!shouldSort);
    setOffset(null);
    setShowAmount(36);
    setCompanies([]);
    setShouldLoad(true);
  };

  useEffect(() => {
    if (shouldLoad) {
      loadCompanies();
      setShouldLoad(false);
    }
  }, [offset, showAmount, companies, shouldLoad]);

  const cancelButtonRef = useRef(null);

  const sendingFrequencyOptions = [
    { label: 'Never', value: '-1' },
    { label: 'Monthly', value: '28' },
    { label: 'Bi-Weekly', value: '14' },
    { label: 'Weekly', value: '7' },
    { label: 'Twice a Week', value: '4' },
    { label: 'Every Three Days', value: '3' },
    { label: 'Three Times a Week', value: '2' },
    { label: 'Daily', value: '1' },
  ];

  const fetchCompanies = async (companySearch) => {
    try {
      const response = await axios.get(
        `https://api.airtable.com/v0/${baseId}/companies`,
        {
          headers: { Authorization: `Bearer ${apiKey}` },
          params: {
            view: 'Grid view',
            filterByFormula: `REGEX_MATCH(LOWER({Name}), '.*${companySearch}.*')`,
          },
        }
      );
      if (companySearch.length < 2) {
        setSearchResults([]);
        return;
      }
      setSearchResults(response.data.records);
      // console.log('returned', response.data.records);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setSearchResults([]);
    }
  };

  useEffect(() => {
    fetchCompanies(companySearch);
  }, [companySearch]);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  // useEffect(() => {
  //   if (companies.length > 1) return;

  //   base("companies")
  //     .select({ view: "Grid view" })
  //     .all()
  //     .then((records) => {
  //       setCompanies(records);
  //     });
  // }, []);

  useEffect(() => {
    base('industries')
      .select({ view: 'Grid view' })
      .all()
      .then((data) => {
        let industriesObj = {};
        data.map(
          (rec) =>
            (industriesObj[rec.fields.Industry] =
              rec.fields.SubIndustries.split(',').map((item) =>
                item.trim().toLowerCase()
              ))
        );

        setIndustries(industriesObj);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (event, company) => {
    event.preventDefault();
    console.log(event.target[0].value);
    console.log('event', event);
    console.log('event target', event.target);

    // setShowNotif(true, setCompanyFormNotif);
    // setContents(
    // 	{ type: "success", heading: "Form Submitted", message: "Thank you for updating your information. Please refresh the page." },
    // 	setCompanyFormNotif
    // );

    // Company Id
    console.log('company', company);
    console.log('company id', company.id);
    var companyId = company.id;

    // // Company Name
    // console.log("company name", event.target["companyName"].value);
    // var companyName = event.target["companyName"].value;

    // // Employees
    // console.log("employees", event.target["employees"].value);
    // var employees = event.target["employees"].value;

    // // Location
    // console.log("location", event.target["location"].value);
    // var location = event.target["location"].value;

    // // Country
    // const countryString = event.target["country"].value;
    // const countriesString = Array.from(event.target["country"])
    //   .map((country) => country.value)
    //   .join(",");
    // console.log("event country:", countryString);
    // console.log("event countries:", countriesString);

    // var country = countryString ? countryString : countriesString;
    // console.log("final country", country);

    // // Industry
    // const industryString = event.target["industry"].value;
    // const industriesString = Array.from(event.target["industry"])
    //   .map((industry) => industry.value)
    //   .join(",");

    // var industry = industryString ? industryString : industriesString;

    // console.log("industry", industry);

    // // Eligible Grants

    // if (secret_1) {
    //   // const eligibleGrant = event.target["eligibleGrants"].value
    //   // console.log('DEBUG 1', eligibleGrant);
    //   const inputGrants = Array.from(event.target["eligibleGrants"]).map((grant) => JSON.parse(grant.value));
    //   // console.log('DEBUG 2', eligibleGrants);
    //   // console.log("event grant:", eligibleGrant);
    //   // console.log("event grants:", eligibleGrants);
    //   // console.log('event target grants', Array.from(event.target["eligibleGrants"])[1]);

    //   console.log("input grants", inputGrants);
    //   console.log("not updated state", eligibleGrants);

    //   eligibleGrants.forEach((grant) => {
    //     console.log("current grant", grant.fields.Name);
    //     console.log("total", inputGrants);
    //     if (!inputGrants?.map((grant) => grant.fields.Name)?.includes(grant.fields.Name)) {
    //       // excludeGrants.push(grant.fields.Name)
    //       setFlaggedGrants((prevArray) => [...prevArray, grant]);
    //       console.log("now flagged", grant.fields.Name);
    //     }
    //   });

    //   flaggedGrants.forEach((grant) => {
    //     if (inputGrants.map((grant) => grant.fields.Name).includes(grant.fields.Name)) {
    //       setFlaggedGrants(flaggedGrants.filter((cur) => cur.fields.Name !== grant.fields.Name));
    //     }
    //   });

    //   // console.log("WHAT ARE THE EXCLUDED", flaggedGrants);

    //   setEligibleGrants(inputGrants);
    // }

    // // Founded
    // var founded = event.target["founded"].value;
    // console.log("founded", event.target["founded"].value);

    // // Annual Revenue
    // var annualRevenue = event.target["annualRevenue"].value.replace(",", "");
    // console.log("annualRevenue", annualRevenue);

    // // Conversion Rate
    // var conversionRate = event.target["conversionRate"].value;
    // console.log("conversionRate", event.target["conversionRate"].value);

    // // Avg Deal Size
    // var avgDealSize = event.target["avgDealSize"].value.replace(",", "");
    // console.log("avgDealSize", event.target["avgDealSize"].value);

    // // Debt
    // var debt = event.target["Debt"].value.replace(",", "");
    // console.log("Debt", event.target["Debt"].value);

    // // Annual Traffic
    // var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
    // console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

    // // Operational Costs
    // var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
    // console.log("OperationalCosts", event.target["OperationalCosts"].value);

    // // List Size
    // var listSize = event.target["ListSize"].value.replace(",", "");
    // console.log("ListSize", event.target["ListSize"].value);

    // // Likely Customers
    // console.log("likelyCustomers", event.target["likelyCustomers"].value);
    // var likelyCustomers = event.target["likelyCustomers"].value;

    // // Description
    // console.log("description", event.target["description"].value);
    // var description = event.target["description"].value;

    // // Technologies
    // const technologyString = event.target["technology"].value;
    // const technologiesString = Array.from(event.target["technology"])
    //   .map((technology) => technology.value.trim())
    //   .join(", ");
    // console.log("event technology:", technologyString);
    // console.log("event technologies:", technologiesString);

    // var technology = technologyString ? technologyString : technologiesString;

    // console.log("technology", technology);

    // var profitable = event.target["profitable-checkbox"].checked === true ? "Yes" : "No";
    // console.log("profitable-checkbox", profitable);

    // let provincesOrStates = Array.from(event.target["province"])
    //   .map((option) => option.value)
    //   .join(",");

    // if (!provincesOrStates) {
    //   provincesOrStates = event.target["province"].value;
    // }
    // console.log("province", provincesOrStates);

    let sendingFrequencyNumValue = parseFloat(
      event.target['sendingFrequencyOption'].value
    );
    let sendingFrequency =
      sendingFrequencyNumValue === -1 ? null : sendingFrequencyNumValue;
    let reportingEmailsValue =
      sendingFrequencyNumValue === -1 ? 'false' : 'true';
    console.log(
      'sendingFrequency and reportingEmail',
      sendingFrequency,
      reportingEmailsValue
    );

    let requestEmails = event.target['requestEmail'];
    if (requestEmails)
      requestEmails = requestEmails.value
        .split(',')
        .map((item) => item.trim())
        .join(',');

    // if (requestEmails && requestEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(requestEmailRef.current, true, 5);
    //   console.log("One or more of the Emails are invlaid. Form not Submitted.");
    //   return;
    // }
    // console.log("Request Email", requestEmails);

    let person1Name = event.target['People1Name'].value;
    console.log('People1Name', person1Name);

    let ccEmails = event.target['CC'];
    if (ccEmails)
      ccEmails = ccEmails.value
        .split(',')
        .map((item) => item.trim())
        .join(',');

    // if (ccEmails && ccEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(ccEmailRef.current, true, 5);
    //   console.log("One or more of the emails are invalid. Form not submitted.");
    //   return;
    // }

    // console.log("CCEmails", ccEmails);

    let bccEmails = event.target['BCC'];
    if (bccEmails)
      bccEmails = bccEmails.value
        .split(',')
        .map((item) => item.trim())
        .join(',');

    // if (bccEmails && bccEmails.split(",").some((email) => !checkEmail(email))) {
    //   highlightErrorOnElement(bccEmailRef.current, true, 5);
    //   console.log("One or more of the emails are invalid. Form not submitted.");
    //   return;
    // }

    // console.log("bcc emails", bccEmails);

    let fromName = event.target['fromName'];
    if (fromName) fromName = fromName.value;
    console.log('from name', fromName);

    let blurEmailString = company?.fields?.blurMe;
    let blurEmailCheckbox = event.target['blurEmail'];
    if (blurEmailCheckbox)
      blurEmailString = blurEmailCheckbox.checked === false ? 'false' : 'true';
    console.log('blurMe', blurEmailString);

    let lastSentValue = company?.fields?.lastSent;
    if (!lastSentValue) {
      lastSentValue = new Date(2024, 0, 1).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }

    // // Debt
    // var debt = event.target["Debt"].value.replace(",", "");
    // console.log("Debt", event.target["Debt"].value);

    // // Annual Traffic
    // var annualTraffic = event.target["AnnualTraffic"].value.replace(",", "");
    // console.log("AnnualTraffic", event.target["AnnualTraffic"].value);

    // // Operational Costs
    // var operationalCosts = event.target["OperationalCosts"].value.replace(",", "");
    // console.log("OperationalCosts", event.target["OperationalCosts"].value);

    // // List Size
    // var listSize = event.target["ListSize"].value.replace(",", "");
    // console.log("ListSize", event.target["ListSize"].value);

    fetch(
      'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        companyId,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: {
            // Name: companyName,
            // Employees: parseFloat(employees),
            // Address: location,
            // Country: country,
            // Industry: industry,
            // Founded: parseFloat(founded),
            // AnnualRevenue: parseFloat(annualRevenue),
            // ConversionRate: parseFloat(conversionRate),
            // AvgDealSize: parseFloat(avgDealSize),
            // Debt: parseFloat(debt),
            // AnnualTraffic: parseFloat(annualTraffic),
            // OperationalCosts: parseFloat(operationalCosts),
            // ListSize: parseFloat(listSize),
            // LikelyCustomers: likelyCustomers,
            // Description: description,
            // Technologies: technology,
            // "Profitable?": profitable,
            // ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
            // ProvinceOrState: provincesOrStates,
            sendFrequency: sendingFrequency,
            reportingEmails: reportingEmailsValue,
            RequestEmail: requestEmails,
            People1Name: person1Name,
            ccEmails: ccEmails,
            bccEmails: bccEmails,
            fromName: fromName,
            blurMe: blurEmailString,
            lastSent: lastSentValue,
          },
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error('Error:', error));

    // setOpen((prev) => !prev);
    // setTimeout(() => setFormSubmitted(true), 1000);

    // setCompany((prev) => {
    //   let newCompany = prev;
    //   newCompany = {
    //     ...newCompany,
    //     fields: {
    //       ...newCompany.fields,
    //       Name: companyName,
    //       Employees: parseFloat(employees),
    //       Address: location,
    //       Country: country,
    //       Industry: industry,
    //       Founded: parseFloat(founded),
    //       AnnualRevenue: parseFloat(annualRevenue),
    //       ConversionRate: parseFloat(conversionRate),
    //       AvgDealSize: parseFloat(avgDealSize),
    //       Debt: parseFloat(debt),
    //       AnnualTraffic: parseFloat(annualTraffic),
    //       OperationalCosts: parseFloat(operationalCosts),
    //       ListSize: parseFloat(listSize),
    //       LikelyCustomers: likelyCustomers,
    //       Description: description,
    //       Technologies: technology,
    //       "Profitable?": profitable,
    //       ExcludeGrants: flaggedGrants?.map((grant) => grant.fields.Name).join(","),
    //       ProvinceOrState: provincesOrStates,
    //       sendFrequency: sendingFrequency,
    //       reportingEmails: reportingEmailsValue,
    //       RequestEmail: requestEmails,
    //       People1Name: person1Name,
    //       ccEmails: ccEmails,
    //       bccEmails: bccEmails,
    //       fromName: fromName,
    //       blurMe: blurEmailString,
    //       lastSent: lastSentValue,
    //     },
    //   };
    //   return newCompany;
    // });

    // setIndustryOptions(getNewIndustrySelectOptions());
  };

  const deleteRecord = (recordId) => {
    fetch(
      'https://api.airtable.com/v0/' +
        baseId +
        '/' +
        companiesTableId +
        '/' +
        recordId,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer keyd3DeTqTwCCeKlF`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((confirmation) => {
        console.log('Record Deleted Confirmation', confirmation);
        setCompanies((prev) =>
          prev.filter((company) => company.id !== recordId)
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Check out our Recommended Partners
          </h2>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            Take a look at some of the reports we've generated for companies
            that we think are a good fit for you{' '}
            <button
              type="button"
              className="ml-2 rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={toggle}
            >
              i
            </button>
          </p>
        </div>

        <div className="mx-auto max-w-7xl text-center">
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <Fragment>
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <div className="flex items-center justify-between">
                    <h2
                      className="hidden sm:block text-base font-semibold leading-7 text-gray-900"
                      id="companies"
                    >
                      Recommended Partners
                    </h2>
                    <span className="inline-flex ml-auto mr- items-center justify-center">
                      <button
                        onClick={handleSort}
                        className="text-sm text-gray-600 mr-4 font-semibold leading-6"
                      >
                        <Tooltip
                          content="Sort by Created Date"
                          placement="bottom"
                          className="z-50 inline"
                        >
                          {shouldSort ? (
                            <BarsArrowUpIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <BarsArrowDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </Tooltip>
                      </button>
                      {filterOptions.Industries.map((industryOption, index) => {
                        return (
                          <div
                            key={industryOption}
                            className={`mx-2 p-2 ${
                              index % 3 === 0
                                ? 'bg-green-200'
                                : index % 3 === 1
                                ? 'bg-indigo-200'
                                : 'bg-amber-200'
                            } ${
                              index % 3 === 0
                                ? 'hover:bg-green-100'
                                : index % 3 === 1
                                ? 'hover:bg-indigo-100'
                                : 'hover:bg-amber-100'
                            } rounded-s-xl rounded-e-xl text-sm max-w-xl w-auto`}
                          >
                            <span className="inline-flex">
                              <p className="text-sm text-gray-900">
                                {industryOption}
                              </p>
                              <button
                                onClick={() => {
                                  setFilterOptions((prev) => ({
                                    ...prev,
                                    Industries: prev.Industries.filter(
                                      (item) => item != industryOption
                                    ),
                                  }));
                                }}
                                aria-hidden="true"
                                className="rounded-md text-white"
                              >
                                <XMarkIcon className="w-4 h-auto ml-auto text-white hover:text-black" />
                              </button>
                            </span>
                          </div>
                        );
                      })}
                      <Menu as="div" className="relative ml-auto mr-1">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <p className="text-sm">Filter By:</p>
                            <Menu.Item>
                              {({ active }) => (
                                <Menu as="div">
                                  <Menu.Button
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'w-full block px-3 py-1 text-sm leading-6 text-gray-900'
                                    )}
                                  >
                                    <span>Industry</span>
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                      <ul className="flex-1 flex-wrap flex-col h-40px">
                                        {Object.keys(industries).length !== 0 &&
                                          Object.keys(industries).map(
                                            (industry) => {
                                              return (
                                                <Menu.Item key={industry}>
                                                  {({ active }) => (
                                                    <button
                                                      className="w-full"
                                                      onClick={() => {
                                                        setFilterOptions(
                                                          (prev) => ({
                                                            ...prev,
                                                            Industries:
                                                              prev.Industries.map(
                                                                (item) =>
                                                                  item
                                                                    .toLowerCase()
                                                                    .trim()
                                                              ).includes(
                                                                industry
                                                                  .trim()
                                                                  .toLowerCase()
                                                              )
                                                                ? prev.Industries.filter(
                                                                    (item) =>
                                                                      item !==
                                                                      industry
                                                                  )
                                                                : [
                                                                    ...prev.Industries,
                                                                    industry,
                                                                  ],
                                                          })
                                                        );
                                                      }}
                                                    >
                                                      <p
                                                        className={classNames(
                                                          active
                                                            ? 'bg-gray-50'
                                                            : '',
                                                          'block px-3 py-1 text-sm leading-6 text-gray-900 w-full'
                                                        )}
                                                      >
                                                        {industry}
                                                      </p>
                                                    </button>
                                                  )}
                                                </Menu.Item>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <button
                                  className={classNames(active ? "bg-gray-50" : "", "block px-3 py-1 text-sm leading-6 text-gray-900")}
                                >
                                  Edit
                                </button>
                              )}
                            </Menu.Item> */}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </span>

                    <div className="">
                      <input
                        type="text"
                        placeholder="Search for a company"
                        className="rounded-md px-3 py-1"
                        value={companySearch}
                        onChange={(e) => setCompanySearch(e.target.value)}
                        // onKeyDown={(e) => e.key === "Enter" && fetchCompanies(e.target.value)}
                      />
                    </div>
                  </div>
                  <ul className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    {companies.length === 0
                      ? [1, 2, 3].map((id) => (
                          <div key={id}>
                            <Skeleton height={200} />
                          </div>
                        ))
                      : displayResults
                          .filter((company) =>
                            company?.fields?.Name?.toLowerCase().includes(
                              companySearch?.trim()?.toLowerCase()
                            )
                          )
                          .filter((company) => {
                            if (filterOptions.Industries.length === 0) {
                              return true;
                            } else {
                              for (const industryOption of filterOptions.Industries) {
                                for (const subindustry of industries[
                                  industryOption
                                ]) {
                                  if (
                                    company?.fields?.Industry?.toLowerCase()
                                      ?.split(',')
                                      ?.map((item) => item.trim())
                                      .includes(subindustry)
                                  ) {
                                    return true;
                                  }
                                }
                              }

                              return false;
                            }
                          })
                          .map((company) => (
                            <li
                              key={company.id}
                              className="overflow-hidden rounded-xl border border-gray-200"
                            >
                              <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                <a
                                  href={
                                    `/?website=${company.fields.Domain}&company=${company.fields.Name}`
                                    // + (admin ? `&${process.env.REACT_APP_QUERY_SECRET_1}=true` : "")
                                  }
                                  className="pointer"
                                >
                                  <img
                                    src={company?.fields?.LogoUrl}
                                    alt={company?.fields?.Name}
                                    className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                  />
                                </a>

                                <div className="text-sm font-medium leading-6 text-gray-900">
                                  {company?.fields?.Name}
                                </div>
                              </div>
                              {isOpen && (
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Revenue</dt>
                                    <dd className="text-gray-700">
                                      <time>
                                        {currencyFormat(
                                          company?.fields?.AnnualRevenue
                                            ? company.fields.AnnualRevenue
                                            : company.fields.Employees * 100000
                                        )}
                                      </time>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Industry</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.Industry || ''}
                                      </div>
                                    </dd>
                                  </div>

                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Country</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.Country || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">To Email</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.RequestEmail || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">
                                      Reporting On / Off
                                    </dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.reportingEmails || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">To Name</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.People1Name || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">From Name</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.fromName || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">
                                      Send Frequency
                                    </dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.sendFrequency || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Last Sent</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.lastSent || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Blurred</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.blurMe || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">cc Emails</dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.ccEmails || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">
                                      bcc Emails
                                    </dt>
                                    <dd className="flex items-start gap-x-2">
                                      <div className="font-medium text-gray-900">
                                        {company?.fields?.bccEmails || ''}
                                      </div>
                                    </dd>
                                  </div>
                                  <form
                                    onSubmit={(e) => handleSubmit(e, company)}
                                  >
                                    <div className="sm:col-span-full">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Manage Company Contact(s)
                                      </label>
                                      <p className="block text-xs font-small text-gray-700 mb-2">
                                        Enter lists of emails (separated by
                                        commas!)
                                      </p>
                                      <span className="items-center justify-center my-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-700">
                                          To Email(s):
                                        </label>
                                        <textarea
                                          defaultValue={
                                            company?.fields?.RequestEmail || ''
                                          }
                                          id="requestEmail"
                                          name="requestEmail"
                                          className="block text-sm rounded-md p-3 ml-2 max-w-screen w-full h-100 resize-y"
                                        />
                                      </span>
                                      <span className="items-center justify-center my-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-700 mt-4">
                                          Who's CC'd on this email?
                                        </label>

                                        <textarea
                                          id="CC"
                                          name="CC"
                                          defaultValue={
                                            company?.fields?.ccEmails || ''
                                          }
                                          className="block text-sm max-w-screen max-w-screen w-full p-3 rounded-md resize-y h-100 ml-2"
                                        />
                                      </span>
                                      <span className="inline-flex items-center justify-center grid grid-cols-1 my-4">
                                        <label className="block text-sm font-medium leading-6 text-gray-700">
                                          Name of First Contact
                                        </label>
                                        <input
                                          id="People1Name"
                                          name="People1Name"
                                          defaultValue={
                                            company?.fields?.People1Name || ''
                                          }
                                          className="block text-sm max-w-screen w-sm p-3 rounded-md h-8 ml-2"
                                        />
                                      </span>
                                      <>
                                        {/* <label className="block text-sm font-medium leading-6 text-gray-900">Admin:</label> */}
                                        <span className="items-center justify-center my-4">
                                          <label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
                                            Who's BCC'd on this email?
                                          </label>
                                          <textarea
                                            id="BCC"
                                            name="BCC"
                                            defaultValue={
                                              company?.fields?.bccEmails || ''
                                            }
                                            className="block text-sm max-w-screen w-full p-3 resize-y rounded-md h-100 ml-2"
                                          />
                                        </span>
                                        <span className="items-center justify-center my-4">
                                          <label className="block text-sm font-medium leading-6 text-gray-900 mt-3">
                                            Who's this email from? (Enter a
                                            name)
                                          </label>
                                          <input
                                            id="fromName"
                                            name="fromName"
                                            defaultValue={
                                              company?.fields?.fromName || ''
                                            }
                                            className="block text-xs max-w-screen w-full p-3 rounded-md h-8 ml-2"
                                          />
                                        </span>
                                        <span className="inline-flex items-center justify-center my-4">
                                          <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Should this email be blurred?
                                          </label>
                                          <input
                                            type="checkbox"
                                            id="blurEmail"
                                            name="blurEmail"
                                            defaultChecked={
                                              company.fields.blurMe === 'true'
                                            }
                                            className="block text-sm ml-3"
                                          />
                                        </span>
                                      </>
                                    </div>
                                    <div className="sm:col-span-full">
                                      <label className="block text-sm font-medium leading-6 text-gray-900">
                                        Sending Frequency
                                      </label>
                                      <select
                                        id="sendingFrequencyOption"
                                        defaultValue={
                                          !company?.fields?.reportingEmails ||
                                          company.fields.reportingEmails ===
                                            'false' ||
                                          !company?.fields?.sendFrequency
                                            ? '-1'
                                            : company.fields.sendFrequency
                                        }
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-w-sm w-full"
                                      >
                                        {sendingFrequencyOptions.map(
                                          (option) => (
                                            <option
                                              key={option.label}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <button
                                      type="submit"
                                      className="mt-4 inline-flex rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                      Save
                                    </button>
                                  </form>
                                </dl>
                              )}
                              {admin && (
                                <div className="ml-auto inline-flex text-gray-300 hover:text-red-400">
                                  <button
                                    className="block"
                                    onClick={() => {
                                      setCurrentCompany(company);
                                      setShowWarningModal(true);
                                    }}
                                  >
                                    <TrashIcon className="h-6 w-6 aria-hidden" />
                                  </button>
                                </div>
                              )}
                            </li>
                          ))}
                  </ul>
                  <div className="items-center text-center mx-auto">
                    <button
                      onClick={() => {
                        if (showAmount - 36 > 0)
                          setShowAmount((prev) => prev - 36);
                      }}
                      className="mt-4 mr-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Show Less
                    </button>
                    {
                      <button
                        onClick={handleShowMore}
                        className="mt-4 ml-1 relative w-lg text-center max-w-sm inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Show More
                      </button>
                    }
                  </div>
                </div>
              </Fragment>
            </div>
            <Transition.Root show={showWarningModal} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setShowWarningModal}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div className="sm:flex sm:items-start">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Delete Company
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Are you sure you want to delete this company?
                                All of the data will be removed from our
                                database.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                            onClick={() => {
                              deleteRecord(currentCompany.id);
                              setCurrentCompany({});
                              setShowWarningModal(false);
                            }}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => {
                              setCurrentCompany({});
                              setShowWarningModal(false);
                            }}
                            ref={cancelButtonRef}
                          >
                            Cancel
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        </div>
      </div>
    </>
  );
}

Companies.defaultProps = {
  admin: false,
};
